/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import { DIV, NodeOrOptional } from '../components/elements';
import { buttonTooltipLeft } from '../components/tooltip';
import * as debug from 'debug';
import tr from '../locale/index';
import { getHighlightedBaseLayers } from './queries';
import { Getter, Setter } from '../components/input/input';

const logger = debug('sdi:map-info/base-layer-switch');

const render = (
    getMapBaseLayer: Getter<string[]>,
    setMapBaseLayer: Setter<string>
): NodeOrOptional => {
    const currents = getMapBaseLayer();
    const buttons = getHighlightedBaseLayers()
        .filter(bl => currents.indexOf(bl.codename) < 0)
        .map(({ codename, img }) =>
            buttonTooltipLeft(tr.core('changeBackgroundMap'), {
                className: 'switch-background',
                style: {
                    backgroundImage: `url(${img})`,
                },
                onClick: () => setMapBaseLayer(codename),
            })
        );

    return DIV('basemap-buttons', ...buttons);
};

export default render;

logger('loaded');
