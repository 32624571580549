import * as io from 'io-ts';
import { RemoteResource, remoteNone } from '../remote';
import { Nullable, Collection } from '../../util';
import {
    PropertyTypeDescriptorIO,
    FeatureIO,
    Feature,
    DirectGeometryObjectIO,
} from './geojson';
import { SparseArray, sparseArray } from '../../lib/sparse-array';

// tslint:disable-next-line: variable-name
export const StreamingFieldIO = io.tuple(
    [io.string, PropertyTypeDescriptorIO],
    'StreamingFieldIO'
);

export type StreamingField = io.TypeOf<typeof StreamingFieldIO>;
export const streamFieldName = (f: StreamingField) => f[0];
export const streamFieldType = (f: StreamingField) => f[1];

// tslint:disable-next-line: variable-name
export const StreamingMetaIO = io.interface(
    {
        count: io.number,
        fields: io.array(StreamingFieldIO),
    },
    'StreamingMetaIO'
);

export type StreamingMeta = io.TypeOf<typeof StreamingMetaIO>;

// tslint:disable-next-line: variable-name
export const StreamingDataIO = io.interface(
    {
        data: io.array(FeatureIO),
        totalCount: io.number,
    },
    'StreamingDataIO'
);

export type StreamingData = io.TypeOf<typeof StreamingDataIO>;

// tslint:disable-next-line: variable-name
export const StreamingRequestSortDirectionIO = io.union(
    [io.literal('DESC'), io.literal('ASC')],
    'StreamingRequestSortDirectionIO'
);

// tslint:disable-next-line: variable-name
export const StreamingRequestSortIO = io.interface(
    {
        column: io.number,
        columnName: io.string,
        direction: StreamingRequestSortDirectionIO,
    },
    'StreamingRequestSortIO'
);

// tslint:disable-next-line: variable-name
export const StreamingRequestFilterStringIO = io.interface(
    {
        tag: io.literal('string'),
        column: io.number,
        columnName: io.string,
        pattern: io.string,
    },
    'StreamingRequestFilterStringIO'
);
// tslint:disable-next-line: variable-name
export const StreamingRequestFilterOpIO = io.union([
    io.literal('eq'),
    io.literal('gt'),
    io.literal('lt'),
]);
// tslint:disable-next-line: variable-name
export const StreamingRequestFilterNumberIO = io.interface(
    {
        tag: io.literal('number'),
        column: io.number,
        columnName: io.string,
        value: io.number,
        op: StreamingRequestFilterOpIO,
    },
    'StreamingRequestFilterNumberIO'
);
// tslint:disable-next-line: variable-name
export const StreamingRequestFilterDateIO = io.interface(
    {
        tag: io.literal('date'),
        column: io.number,
        columnName: io.string,
        date: io.string,
        op: StreamingRequestFilterOpIO,
    },
    'StreamingRequestFilterDateIO'
);
// tslint:disable-next-line: variable-name
export const StreamingRequestFilterDateTimeIO = io.interface(
    {
        tag: io.literal('datetime'),
        column: io.number,
        columnName: io.string,
        datetime: io.string,
        op: StreamingRequestFilterOpIO,
    },
    'StreamingRequestFilterDateTimeIO'
);
// tslint:disable-next-line: variable-name
export const StreamingRequestFilterTermIO = io.interface(
    {
        tag: io.literal('term'),
        column: io.number,
        columnName: io.string,
        term: io.number,
    },
    'StreamingRequestFilterTermIO'
);
// tslint:disable-next-line: variable-name
export const StreamingRequestFilterIO = io.union(
    [
        StreamingRequestFilterStringIO,
        StreamingRequestFilterNumberIO,
        StreamingRequestFilterDateIO,
        StreamingRequestFilterDateTimeIO,
        StreamingRequestFilterTermIO,
    ],
    'StreamingRequestFilterIO'
);

// tslint:disable-next-line: variable-name
export const StreamingRequestIO = io.intersection([
    io.interface({
        offset: io.number,
        limit: io.number,
    }),
    io.partial({ sort: StreamingRequestSortIO }),
    io.partial({ filters: io.array(StreamingRequestFilterIO) }),
    io.partial({ geometry: DirectGeometryObjectIO }),
]);

// tslint:disable-next-line: variable-name
export const CSVRequestIO = io.intersection([
    io.interface({
        layerInfo: io.string,
    }),
    io.partial({ sort: StreamingRequestSortIO }),
    io.partial({ filters: io.array(StreamingRequestFilterIO) }),
]);

// tslint:disable-next-line: variable-name
export const CSVDataIO = io.interface(
    {
        data: io.array(io.array(io.unknown)),
    },
    'CSVDataIO'
);

export const ExportConfigIO = io.interface({
    fields: io.array(io.string),
    filters: io.array(StreamingRequestFilterIO),
});

export type ExportConfig = io.TypeOf<typeof ExportConfigIO>;

export type CSVData = io.TypeOf<typeof CSVDataIO>;

export type StreamingRequestSortDirection = io.TypeOf<
    typeof StreamingRequestSortDirectionIO
>;
export type StreamingRequestSort = io.TypeOf<typeof StreamingRequestSortIO>;
export type StreamingRequestFilter = io.TypeOf<typeof StreamingRequestFilterIO>;
export type StreamingRequest = io.TypeOf<typeof StreamingRequestIO>;

export type CSVRequest = io.TypeOf<typeof CSVRequestIO>;

export const { pushSparse, getSparse, getRanges, newSparseArray } =
    sparseArray<Feature>();

export interface StreamingRecord {
    range: Range;
    totalCount: number;
}

export interface StreamingState {
    url: Nullable<string>;
    meta: RemoteResource<StreamingMeta>;
    totalCount: number;
    storage: SparseArray<Feature>;
    remotes: Collection<RemoteResource<number>>;
}

export const initialStreamingState = (): StreamingState => ({
    url: null,
    meta: remoteNone,
    totalCount: -1,
    storage: newSparseArray(),
    remotes: {},
});

export const hashRequestFilter = (f: StreamingRequestFilter): string => {
    const value = (() => {
        switch (f.tag) {
            case 'string':
                return f.pattern;
            case 'number':
                return `${f.value}-${f.op}`;
            case 'date':
                return `${f.date}-${f.op}`;
            case 'datetime':
                return `${f.datetime}-${f.op}`;
            case 'term':
                return `${f.term}`;
        }
    })();

    return `${f.tag}-${f.column}-${value}`;
};

// (function test() {
//     const sa = sparseArray<number>();

//     let s = sa.newSparseArray();

//     const a = [1, 2, 3];
//     const b = [6, 7, 8, 9];
//     const c = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];

//     s = sa.pushSparse(s, 100, a);
//     console.log('A', JSON.parse(JSON.stringify(s)));
//     s = sa.pushSparse(s, 106, b);
//     console.log('B', JSON.parse(JSON.stringify(s)));

//     s = sa.pushSparse(s, 99, c);
//     console.log('C', JSON.parse(JSON.stringify(s)));
// })();
