import * as debug from 'debug';

import { DIV, IMG, NODISPLAY } from 'sdi/components/elements';
import { Position, DirectGeometryObject } from 'sdi/source';
import miniMap, {
    miniMapLoading,
    miniMapLoaded,
    miniMapFailed,
} from 'sdi/map/mini';
import { getMiniMap } from 'view/src/queries/harvest';
import { setMiniMap } from 'view/src/events/harvest';

const logger = debug('sdi:angled/read/geometry');

const map = miniMap(
    'https://geoservices-urbis.irisnet.be/geoserver/UrbisAdm/ows?request=GetCapabilities&service=WMS&version=1.3.0',
    // 'https://geoservices-urbis.irisnet.be/geoserver/ows/urbis.irisnet.be',
    // 'https://geodata.environnement.brussels/webservice/wmsproxy/urbis.irisnet.be',
    {
        LAYERS: 'urbisFRGray',
        WIDTH: 512,
        HEIGHT: 512,
    }
);

const hashCoordinate = (p: Position) => `${p[0]},${p[1]}`;

const hashGeom = (geom: DirectGeometryObject) => {
    switch (geom.type) {
        case 'Point':
            return hashCoordinate(geom.coordinates);
        case 'LineString':
            return geom.coordinates.map(hashCoordinate).join('~');
        case 'Polygon':
            return geom.coordinates
                .map(multi => multi.map(hashCoordinate).join('+'))
                .join('~');
        case 'MultiPoint':
            return geom.coordinates.map(hashCoordinate).join('~');
        case 'MultiLineString':
            return geom.coordinates
                .map(multi => multi.map(hashCoordinate).join('+'))
                .join('~');
        case 'MultiPolygon':
            return geom.coordinates
                .map(multi =>
                    multi
                        .map(inner => inner.map(hashCoordinate).join('+'))
                        .join('~')
                )
                .join('/');
    }
};

export const render = (value: DirectGeometryObject) =>
    DIV(
        { className: 'field__value field--read' },
        getMiniMap(hashGeom(value)).foldL(
            () => {
                logger(`starting minimap ${hashGeom(value).slice(0, 16)}`);
                setMiniMap(hashGeom(value), miniMapLoading());
                map(value)
                    .then(opt =>
                        opt.map(urlString =>
                            setMiniMap(
                                hashGeom(value),
                                miniMapLoaded(urlString)
                            )
                        )
                    )
                    .catch(() => setMiniMap(hashGeom(value), miniMapFailed()));
                return NODISPLAY();
            },
            step => {
                logger(
                    `stepping minimap ${hashGeom(value).slice(0, 16)} -> ${
                        step.step
                    }`
                );
                switch (step.step) {
                    case 'loading':
                        return DIV({ className: '_loader' }, '~loading');
                    case 'failed':
                        return DIV({ className: '_failed' }, '~failed');
                    case 'loaded':
                        return IMG({ src: step.data, alt: '' });
                }
            }
        )
    );

export default render;

logger('loaded');
