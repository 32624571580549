/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import Collection from 'ol/Collection';
import Feature from 'ol/Feature';
import LayerVector from 'ol/layer/Vector';
import SourceVector from 'ol/source/Vector';
import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Circle from 'ol/style/Circle';
import Text from 'ol/style/Text';
import Geometry from 'ol/geom/Geometry';
import RenderFeature from 'ol/render/Feature';

import { fontSizeExtractRegexp, fontSizeReplaceRegexp } from '../style';
import { FeaturePathGetter, FeaturePath, HighlightOptions } from '..';
import { fromNullable, none } from 'fp-ts/lib/Option';
import { scopeOption } from '../../lib/scope';
import { isNotNullNorUndefined, Nullable } from '../../util';
import { VectorLayer } from '../map';

const fontSizeIncrement = (s: string) => {
    const result = fontSizeExtractRegexp.exec(s);
    if (!result) {
        return s;
    }
    if (result.length !== 2) {
        return s;
    }
    const ret = parseFloat(result[1]) * 1.3;
    if (isNaN(ret)) {
        return s;
    }
    return s.replace(
        fontSizeReplaceRegexp,
        (_m: string, p1: string, p2: string) =>
            `${p1} ${ret.toFixed(1)}px ${p2}`
    );
};

export const getSelectionStyleForPoint = (style: Style) => {
    const text = style.getText();
    if (text && text.getText()) {
        return new Style({
            text: new Text({
                font: fromNullable(text.getFont())
                    .map(fontSizeIncrement)
                    .toUndefined(),
                text: text.getText(),
                textAlign: text.getTextAlign(),
                textBaseline: text.getTextBaseline(),
                offsetX: text.getOffsetX(),
                offsetY: text.getOffsetY(),
                fill: new Fill({
                    color: '#3FB2FF',
                }),
                stroke: new Stroke({
                    width: 2,
                    color: 'white',
                }),
            }),
        });
    }
    return new Style();
};

const ensureArray = <T>(a: T | T[]): T[] => {
    if (Array.isArray(a)) {
        return a;
    }
    return [a];
};

export const getStylesForFeature = (
    layerRef: VectorLayer,
    f: Feature<Geometry>,
    res: number
) => {
    const fn = f.getStyleFunction() ?? ((_a, _b) => []);
    if (fn) {
        const styles = fn(f, res) ?? [];
        return ensureArray<Style>(styles);
    }

    const fs = f.getStyle();
    if (fs) {
        if (typeof fs === 'function') {
            const styles = fs(f, res) ?? [];
            return ensureArray<Style>(styles);
        }
        return ensureArray(fs);
    }

    if (layerRef) {
        const fn = layerRef.getStyleFunction();
        if (fn) {
            return ensureArray(fn(f, res));
        }
        const fs = layerRef.getStyle();
        if (fs) {
            if (typeof fs === 'function') {
                return ensureArray(fs(f, res));
            }
            return ensureArray(fs);
        }
    }
    return null;
};

const selectionStyle =
    (_layer: VectorLayer) =>
    (f: Feature<Geometry> | RenderFeature, _res: number) =>
        fromNullable(f.getGeometry())
            .map(geom => {
                const geometryType = geom.getType();
                if (geometryType === 'Point') {
                    // const styles = getStylesForFeature(layer, f, res);
                    // if (styles) {
                    //     return styles.map(getSelectionStyleForPoint);
                    // }
                    return [
                        new Style({
                            image: new Circle({
                                radius: 12,
                                fill: new Fill({
                                    color: '#3FB2FF',
                                }),
                                stroke: new Stroke({
                                    width: 2,
                                    color: 'white',
                                }),
                            }),
                        }),
                    ];
                } else if (
                    geometryType === 'LineString' ||
                    geometryType === 'MultiLineString'
                ) {
                    return [
                        new Style({
                            stroke: new Stroke({
                                width: 4,
                                color: 'white',
                            }),
                        }),
                        new Style({
                            stroke: new Stroke({
                                width: 2,
                                color: '#3FB2FF',
                            }),
                        }),
                    ];
                }

                return [
                    new Style({
                        fill: new Fill({
                            color: '#3FB2FF',
                        }),
                        stroke: new Stroke({
                            width: 2,
                            color: 'white',
                        }),
                    }),
                ];
            })
            .getOrElse([]);

const getFeature = (
    refLayers: Nullable<Collection<VectorLayer>>,
    fp: FeaturePath
) => {
    const { featureId, layerId } = fp;
    if (
        isNotNullNorUndefined(refLayers) &&
        isNotNullNorUndefined(featureId) &&
        isNotNullNorUndefined(layerId)
    ) {
        return scopeOption()
            .let(
                'layer',
                fromNullable(
                    refLayers.getArray().find(l => l.get('id') === layerId)
                )
            )
            .let('feature', ({ layer }) =>
                fromNullable(layer.getSource()?.getFeatureById(featureId))
            );
    }
    return none;
};

export const highlight = (options: FeaturePathGetter | HighlightOptions) => {
    let refLayers: Collection<VectorLayer> | null = null;
    const hlSource = new SourceVector();
    const hlLayer = new LayerVector({
        source: hlSource,
        zIndex: 1000,
    });
    const fpg = typeof options === 'function' ? options : options.getSelected;
    const filter =
        typeof options === 'function'
            ? () => true
            : options.filter === undefined
            ? () => true
            : options.filter;
    const update = () =>
        getFeature(refLayers, fpg()).fold(
            hlSource.clear(),
            ({ layer, feature }) => {
                if (filter(feature, layer)) {
                    const style =
                        typeof options === 'function'
                            ? selectionStyle(layer)
                            : options.getStyle !== undefined
                            ? options.getStyle
                            : selectionStyle(layer);
                    const fc = feature.clone();
                    fc.setStyle(style);
                    hlSource.clear();
                    hlSource.addFeature(fc);
                }
            }
        );

    const init = (
        layers: Collection<VectorLayer>,
        tools: Collection<VectorLayer>
    ) => {
        tools.push(hlLayer);
        refLayers = layers;
    };

    return { init, update };
};
