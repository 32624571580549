/*
 *  Copyright (C) 2019 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import * as debug from 'debug';
import { fromNullable } from 'fp-ts/lib/Option';
import { query } from 'sdi/shape';
import {
    FeatureCollection,
    getFeatureProp,
    ILayerInfo,
    Inspire,
    MessageRecord,
    PointDiscreteGroup,
} from 'sdi/source';
import { scopeOption } from 'sdi/lib';

const logger = debug('sdi:query/bookmark');

export interface BookmarkProperties {
    name: string;
}

export const bookmarkLayerID = '__bookmarks_info__';
export const bookmarkMetadataID = '__bookmarks_meta__';
export const bookmarkLayerName: MessageRecord = {
    en: 'Bookmarks',
    fr: 'Repères',
    nl: 'Markeerstiften',
};

const bookmarkGroups = (): PointDiscreteGroup[] => {
    const colors = getBookmarks()
        .features.reduce((acc, val) => {
            acc.add(getFeatureProp(val, 'color', 'black'));
            return acc;
        }, new Set<string>())
        .keys();
    return Array.from(colors).map(c => ({
        label: {},
        marker: {
            codePoint: 0xf005,
            color: c,
            size: 18,
        },
        values: [c],
    }));
};

export const bookmarkLayerInfo = (): ILayerInfo => ({
    id: bookmarkLayerID,
    metadataId: bookmarkMetadataID,
    visible: true,
    featureViewOptions: { type: 'default' },
    style: {
        kind: 'point-discrete',
        // marker: {
        //     codePoint: 0xf005,
        //     color: 'black',
        //     size: 18,
        // },
        groups: bookmarkGroups(),
        propName: 'color',
        label: {
            propName: { fr: 'name', nl: 'name', en: 'name' },
            color: 'black',
            size: 10,
            align: 'center',
            baseline: 'bottom',
            resLimit: 5,
            yOffset: -12,
        },
    },
    group: null,
    legend: null,
    visibleLegend: true,
    opacitySelector: false,
});

export const bookmarkMetadata: Inspire = {
    id: bookmarkMetadataID,
    uniqueResourceIdentifier: bookmarkLayerID,
    dataStreamUrl: null,
    resourceTitle: bookmarkLayerName,
    geographicBoundingBox: { east: 0, west: 0, south: 0, north: 0 },
    keywords: [],
    geometryType: 'Point',
    metadataDate: '',
    metadataPointOfContact: [],
    published: false,
    resourceAbstract: { fr: '', nl: '', en: '' },
    responsibleOrganisation: [],
    temporalReference: { revision: '' },
    topicCategory: [],
    maintenanceFrequency: 'unknown',
};

export const defaultBookmarks = (): FeatureCollection => ({
    features: [],
    type: 'FeatureCollection',
});

export const getBookmarks = () => {
    const layers = query('data/layers');
    if (bookmarkLayerID in layers) {
        return layers[bookmarkLayerID];
    }
    return defaultBookmarks();
};

export const withoutBookmarks = (ls: ILayerInfo[]) =>
    ls.filter(l => l.id !== bookmarkLayerID);

export const getBookmarkIndex = () =>
    fromNullable(query('component/bookmark/current-index'));

export const getBookmarkName = () =>
    scopeOption()
        .let('bix', getBookmarkIndex())
        .let('bml', fromNullable(query('data/layers')[bookmarkLayerID]))
        .let('name', ({ bix, bml }) =>
            fromNullable(bml.features?.[bix]?.properties?.name as string)
        )
        .pick('name');

export const getBookmarkColor = () =>
    scopeOption()
        .let('bix', getBookmarkIndex())
        .let('bml', fromNullable(query('data/layers')[bookmarkLayerID]))
        .let('color', ({ bix, bml }) =>
            fromNullable(bml.features?.[bix]?.properties?.color as string)
        )
        .pick('color')
        .getOrElse('black');

logger('loaded');
