import { MessageStore, formatMessage } from 'sdi/locale';

const messages = {
    mapList: {
        fr: 'Liste des cartes',
        nl: 'Lijst van kaarten', // nldone
        en: 'Maps list',
    },

    'tooltip:info': {
        fr: 'Informations sur la carte',
        nl: 'Informatie op de kaart', // nldone
        en: 'Map informations',
    },

    'tooltip:legend': {
        fr: 'Légende',
        nl: 'Legende', // nldone
        en: 'Legend',
    },

    'tooltip:dataAccess': {
        fr: 'Accès aux données',
        nl: 'Toegang tot de gegevens', // nldone
        en: 'Access to data',
    },
    'tooltip:dataNoExport': {
        fr: 'Export des données non disponible',
        nl: 'Download niet beschikbaar', // nl TODO
        en: 'Download not available',
    },

    'tooltip:dataAndSearch': {
        fr: 'Données et recherche',
        nl: 'Data en zoeken', // nldone
        en: 'Data and search',
    },

    'tooltip:base-map': {
        fr: 'Fond de carte',
        nl: 'Achtergrond van de kaart', // nldone
        en: 'Background map',
    },

    'tooltip:print': {
        fr: 'Exporter et imprimer',
        nl: 'Exporteren en afdrukken', // nldone
        en: 'Export and print',
    },

    'tooltip:ishare': {
        fr: 'Partager la carte',
        nl: 'Deel de kaart', // nldone
        en: 'Share the map',
    },

    'tooltip:measure': {
        fr: 'Faire des mesures',
        nl: 'Metingen uitvoeren', // nldone
        en: 'Take measurements',
    },

    'tooltip:locate': {
        fr: 'Outils de géolocalisation',
        nl: 'Geolokalisatie tools', // nldone
        en: 'Geolocation tools',
    },
    'tooltip:spatial-filter': {
        fr: 'Filtre spatial',
        nl: 'Ruimtelijke filter',
        en: 'Spatial filter',
    },

    'helptext:measureTool': {
        fr: 'Effectuez des mesures sur la carte à l’aide des outils ci-dessous. ',
        nl: 'Voer metingen uit op de kaart met behulp van de onderstaande tools.', // nldone
        en: 'Make measurements on the map using the tools below.',
    },

    'helptext:bookmark': {
        fr: "Cliquez sur la carte pour créer un marque-page et conserver une localisation sur l'ensemble de la plateforme.",
        nl: 'Klik op de kaart om een bladwijzer aan te maken en een localisatie op het hele platform te behouden.', // nldone
        en: 'Click on the map to create a bookmark and keep a location on the entire platform.',
    },

    'helptext:measureHelp': {
        fr: 'Double-cliquez pour terminer une mesure et en ajouter une nouvelle.',
        nl: 'Dubbelklik om een meting te voltooien en een nieuwe meting toe te voegen.', // nldone
        en: 'Double-click to complete a measurement and add a new one.',
    },

    identifier: {
        fr: 'Identifiant',
        nl: 'Identificatie', // nldone
        en: 'Identifier',
    },

    'helptext:gpsTracker': {
        fr: 'Cette fonctionnalité requière d’acceder à la localisation de votre appareil. Ces informations ne sont ni stockées, ni partagées.',
        nl: 'Deze functie vereist dat u toegang heeft tot de localisatie van uw toestel. Deze informatie wordt niet opgeslagen of gedeeld.', // nldone
        en: 'This feature requires the app to access the location of your device. This information is not stored nor shared.',
    },

    'helptext:noExport': {
        fr: 'Veuillez contacter les responsables de la plateforme pour accéder aux données. ',
        nl: 'Neem contact op met de beheerders van het platform om toegang te krijgen tot de gegevens. ', // nl todo
        en: 'Please contact the platform managers to access the data. ',
    },
    'helptext:printWarning': {
        fr: "Dans certaines configurations, les couches n'apparaissent pas lors de l'export PDF. En attendant que le problème soit résolu, nous pouvons vous envoyer le PDF par e-mail.",
        nl: 'In sommige configuraties verschijnen de lagen niet in de PDF-export. Tot het probleem is opgelost, kunnen we u de PDF per e-mail sturen.', // nl todo
        en: 'Please contact the platform managers if you have any problem with the PDF-export.',
    },

    mapStatusText: {
        fr: 'Status de publication : ',
        nl: 'Publicatie status : ', // nldone
        en: 'Publication status : ',
    },

    extractFeatures: {
        fr: 'Forage',
        nl: 'Doorzoeken', // nldone
        en: 'Extract',
    },

    links: {
        fr: 'Liens',
        nl: 'Links', // nldone
        en: 'Links',
    },
    link: {
        fr: 'Lien',
        nl: 'Link', // nldone
        en: 'Link',
    },
    extractOn: {
        fr: 'Intersection avec la géométrie sélectionnée',
        nl: 'Intersectie met het geselecteerde geometrie', // nltodo
        en: 'Intersection with selected geometry',
    },

    extractOff: {
        fr: 'Tous les éléments',
        nl: 'Alle elementen', // nldone
        en: 'All items',
    },

    emptyMapDescription: {
        fr: 'Description de la carte',
        nl: 'Kaartbeschrijving', // nldone
        en: 'Map description',
    },

    emptyMapTitle: {
        fr: 'Adapter le titre de la carte',
        nl: 'De kaarttitel aanpassen', // nldone
        en: 'Adjust map title',
    },

    printDownloadingBaseMap: {
        fr: 'Téléchargement du fond de carte',
        nl: 'De achtergrond van de kaart downloaden', // nldone
        en: 'Downloading background map',
    },

    printNotStarted: {
        fr: 'Rendu des couches vectorielles',
        nl: 'Weergave van vectorlagen', // nldone
        en: 'Rendering datas',
    },

    printPreparingPDF: {
        fr: 'Génération du PDF',
        nl: 'PDF voorbereiden', // nldone
        en: 'PDF generation',
    },

    relatedMapsLabel: {
        fr: 'Cartes liées',
        nl: 'Gerelateerde kaarten', // nldone
        en: 'Related maps',
    },

    wmsSwitch: {
        fr: 'Fond de carte',
        nl: 'Achtergrond van de kaart', // nldone
        en: 'Background map',
    },

    'helptext:wmsSwitchTool': {
        fr: 'Sélectionnez un des webservices référencés ci-dessous pour changer le fond de carte.',
        nl: 'Selecteer één van de onderstaande webservices om de basiskaart te wijzigen.', // nldone
        en: 'Select one of the webservices listed below to change the base map.',
    },

    measureTool: {
        fr: 'Outils de mesure',
        nl: 'Meetinstrumenten', // nldone
        en: 'Measure tools',
    },

    geocode: {
        fr: 'Chercher une adresse',
        nl: 'Een adres zoeken', // nldone
        en: 'Search an adress',
    },

    copy: {
        fr: 'Copier',
        nl: 'Kopiëren', // nldone
        en: 'Copy',
    },

    copied: {
        fr: 'Copié !',
        nl: 'Gekopieerd !', // nldone
        en: 'Copied !',
    },

    sharingTools: {
        fr: 'Partage de la carte',
        nl: 'Deel de kaart', // nldone
        en: 'Sharing the map',
    },

    shareWithView: {
        fr: 'Partager avec le niveau de zoom et le centrage actuel.',
        nl: 'Delen met het huidige zoomniveau en middelpunt.', // nldone
        en: 'Share with zoom level and actual center',
    },

    'helptext:share': {
        fr: 'Utilisez les liens ci-dessous pour partager la carte, ou une vue détaillée de celle-ci.',
        nl: 'Gebruik de onderstaande links om de kaart te delen, of een gedetailleerde weergave ervan.', // nldone
        en: 'Use the links below to share the map, or a detailed view of it.',
    },

    'helptext:embed': {
        fr: 'Utilisez le code HTML ci-dessous pour intégrer la carte, ou une vue détaillée de celle-ci dans votre site-web.',
        nl: 'Gebruik de onderstaande HTML-code om de kaart, of een gedetailleerde weergave ervan in uw website te integreren.', // nldone
        en: 'Use the HTML code below to embed the map, or a detailed view of it in your website.',
    },

    'helptext:locationTool': {
        fr: 'Obtenez ou utilisez des données de géolocalisation à l’aide des outils ci-dessous.',
        nl: 'Verkrijgen of gebruiken van de geolokalisatiedata met behulp van onderstaande tools.', // nldone
        en: 'Obtain or use geolocation data using the tools below.',
    },

    'helptext:pointLocationTool': {
        fr: 'Les coordonnées doivent être encodées dans le système Lambert Belge 72 (EPSG:31370)',
        nl: 'Coördinaten moeten worden geëncodeerd in het Belgische Lambert systeem 72 (EPSG: 31370)', // nldone
        en: 'Coordinates must be encoded in Belgian Lambert 72 system (EPSG:31370)',
    },

    measureLength: {
        fr: 'Mesurer une longueur',
        nl: 'Een lengte meten', // nldone
        en: 'Measure a length',
    },

    measureArea: {
        fr: 'Mesurer une superficie',
        nl: 'Een oppervlakte meten', // nldone
        en: 'Measure an area',
    },

    mapEmbed: {
        fr: 'Inclure la carte',
        nl: 'De kaart invoegen', // nldone
        en: 'Embed map',
    },

    mapEmbedWithView: {
        fr: 'Inclure la vue actuelle de la carte',
        nl: 'De huidige weergave van de kaart invoegen', // nldone
        en: 'Embed current view',
    },

    location: {
        fr: 'Outils de géolocalisation',
        nl: 'Tools voor geolokalisatie', // nldone
        en: 'Geolocation tools',
    },

    cursorLocalisation: {
        fr: 'Géolocaliser le curseur',
        nl: 'Geolokaliseer de cursor', // nldone
        en: 'Geo-locate the cursor',
    },

    'helptext:cursorLocationOn': {
        fr: `Visualiser des coordonnées au survol du curseur. 
        Cliquez sur la carte pour enregistrer les coordonnées.
         Coordonnées Lambert72 - EPSG31370.`,
        nl: `Coördinaten bekijken wanneer u boven de cursor zweeft. 
        Klik op de kaart om de coördinaten op te slaan.
         Lambert72 - EPSG31370 - contactgegevens`, // nltocheck
        en: `View coordinates when hovering over the cursor. 
        Click on the map to save the coordinates.
         Lambert72 - EPSG31370 contact details`,
    },

    'helptext:cursorLocationOff': {
        fr: 'Obtenir des coordonnées en cliquant sur la carte.',
        nl: 'Klik op de kaart voor contactgegevens.', // nltocheck
        en: 'Get contact information by clicking on the map.',
    },

    longitude: {
        fr: 'Longitude (X)',
        nl: 'Lengtegraad (X)', // nldone
        en: 'Longitude (X)',
    },

    latitude: {
        fr: 'Latitude (Y)',
        nl: 'Breedtegraad (Y)', // nldone
        en: 'Latitude (Y)',
    },

    pointLocation: {
        fr: 'Localisation d’un point',
        nl: 'Localisatie van een punt', // nldone
        en: 'Point location',
    },

    bookmark: {
        fr: 'Repère',
        nl: 'Markeerstift', // nldone
        en: 'Marker',
    },

    bookmarks: {
        fr: 'Repères',
        nl: 'Markeerstiften', // nldone
        en: 'Markers',
    },

    editBookmark: {
        fr: 'Editer le nom du repère',
        nl: 'Bewerk de naam van de marker', // nldone
        en: 'Edit marker name',
    },

    addBookmark: {
        fr: 'Ajouter un repère sur la carte',
        nl: 'Voeg een markering toe op de kaart', // nldone
        en: 'Add a marker on the map',
    },

    printSmallFormat: {
        fr: 'Petit format (A4)',
        nl: 'Klein formaat (A4)', // nldone
        en: 'Small format (A4)',
    },

    printMediumFormat: {
        fr: 'Format moyen (A3)',
        nl: 'Medium formaat (A3)', // nltodo
        en: 'Medium format (A3)',
    },

    printBigFormat: {
        fr: 'Grand format (A0)',
        nl: 'Groot formaat (A0)', // nldone
        en: 'Large format (A0)',
    },

    printMap: {
        fr: 'Export et impression',
        nl: 'Exporteren en printen', // nldone
        en: 'Export and print',
    },

    'helptext:printMapTool': {
        fr: 'Exportez la vue actuelle de la carte en un fichier .PDF prêt à imprimer en sélectionnant le format et l’orientation souhaités.',
        nl: 'Exporteer de huidige weergave van de kaart als een drukklaar PDF-bestand door het gewenste formaat en de oriëntatie te selecteren.', // nldone
        en: 'Export the current map view as a print-ready PDF file by selecting the desired format and orientation.',
    },

    visible: {
        fr: 'Visibilité',
        nl: 'Zichtbaarheid', // nldone
        en: 'Visibility',
    },

    attributesTable: {
        fr: 'Table attributaire',
        nl: 'Attributentabel', // nldone
        en: 'Attribute table',
    },

    wmsLegendDisplay: {
        fr: 'Montrer la légende du fond de carte',
        nl: 'De legende van de kaart op de achtergrond tonen', // nldone
        en: 'Show background legend',
    },

    wmsLegendHide: {
        fr: 'Masquer la légende du fond de carte',
        nl: 'De legende van de kaart op de achtergrond verbergen', // nldone
        en: 'Hide background legend',
    },

    lastModified: {
        fr: 'Mise à jour de la carte le ',
        nl: 'Kaart update op ', // nldone
        en: 'Map update on ',
    },

    mapLegend: {
        fr: 'Légende',
        nl: 'Legende', // nldone
        en: 'Legend',
    },

    mapData: {
        fr: 'Données',
        nl: 'Data', // nldone
        en: 'Data',
    },

    'helptext:mapdataTool': {
        fr: 'Affichez ou masquez certaines couches, et explorez les données brutes en utilisant la table attributaire.',
        nl: 'Toon of verberg bepaalde lagen en verken de ruwe gegevens met behulp van de attributententabel.', // nldone
        en: 'Show or hide certain layers, and explore the raw data using the attribute table',
    },
    'helptext:mapdataToolSimple': {
        fr: 'Explorez les données filtrées en utilisant les tables attributaires.',
        nl: 'Verken de gefiltreerde gegevens met behulp van de attributententabels.', // nldone
        en: 'Explore the filtered data using the attribute tables',
    },

    zoomOnFeature: {
        fr: 'Zoomer sur l’entité ',
        nl: 'Zoom in op de entiteit ', // nldone
        en: 'Zoom to item ',
    },
    zoomOnFeatureGroup: {
        fr: 'Zoomer sur les entités ',
        nl: 'Zoom in op de entiteiten ', // nldone
        en: 'Zoom to items ',
    },
    activateLayerAndZoomOnFeature: {
        fr: 'Activer la couche & Zoomer sur l’entité ',
        nl: 'Activeer de laag & Zoom in op de entiteit ', // nldone
        en: 'Zoom to item ',
    },

    remove: {
        fr: 'Supprimer',
        nl: 'Verwijderen', // nldone
        en: 'Remove',
    },
    total: {
        fr: 'total',
        nl: 'totaal', // nldone
        en: 'total',
    },

    loadingData: {
        fr: 'Chargement des données',
        nl: 'Data worden geladen', // nldone
        en: 'Loading datas',
    },

    layerId: {
        fr: 'Identifiant de la ressource',
        nl: 'Identificatie van de bron', // nldone
        en: 'Resource ID',
    },
    layer: {
        fr: 'couche',
        nl: 'layer',
        en: 'layer',
    },
    layerName: {
        fr: 'Nom de la couche',
        nl: 'Layernaam',
        en: "Layer's name",
    },

    mapLink: {
        fr: 'Lien vers la carte',
        nl: 'Link naar de kaart', // nldone
        en: 'Link to the map',
    },

    mapLinkWithView: {
        fr: 'Lien vers la vue actuelle de la carte',
        nl: 'Link naar de huidige kaartweergave', // nldone
        en: 'Link to the current view of the map',
    },

    embed: {
        fr: 'Inclure dans votre site web',
        nl: 'Integreer in uw website', // nldone
        en: 'Embed in your web site',
    },

    stop: {
        fr: 'Arrêter',
        nl: 'Stoppen', // nldone
        en: 'Stop',
    },

    startGPS: {
        fr: 'Démarrer la localisation GPS',
        nl: 'Start de GPS-localisatie', // nldone
        en: 'Start GPS location',
    },

    originalTitle: {
        fr: 'Titre original',
        nl: 'Originele titel', // nldone
        en: 'Original title',
    },

    searchAtlas: {
        fr: 'Chercher dans l’atlas',
        nl: 'In de atlas zoeken', // nldone
        en: 'Search in atlas',
    },

    pitchAtlas: {
        fr: `Bienvenue sur l'atlas`,
        nl: 'Welkom bij de atlas', // nldone
        en: 'Welcome to the atlas',
    },
    subPitchAtlas: {
        fr: `Cette plateforme est en constante évolution, pour toute question ou suggestion, n'hésitez pas à nous contacter`,
        nl: 'Dit platform is voortdurend in ontwikkeling, als u vragen of suggesties hebt, aarzel dan niet om contact met ons op te nemen.', // nldone
        en: 'This platform is constantly evolving, if you have any questions or suggestions, please do not hesitate to contact us',
    },

    embedCommentSnippet1: {
        fr: 'Le code suivant propose une intégration responsive, avec un rapport de taille fixé par défaut.',
        nl: 'Dit codefragment moet een schone en responsieve inbedding bieden voor desktop en mobiel, met een standaardgrootteverhouding.', // nltodo
        en: 'This code snippet should provides a clean and responsive embed for desktop and mobile, with a default size ratio.',
    },

    embedCommentSnippet2: {
        fr: "Si vous souhaitez gérer vous-même la taille de la boîte contenant l'iframe, supprimez le style ci-dessous et la DIV #iFrameAtlas.",
        nl: 'Als je zelf de grootte van de doos met het iframe wilt beheren, verwijder dan de onderstaande stijl en de #iFrameAtlas DIV', // nltodo
        en: 'If you want to manage yourself the size of the box containing the iframe, remove the style below and the #iFrameAtlas DIV.',
    },

    queryTitle: {
        fr: 'Recherche par forage',
        nl: 'Booronderzoek', // nl tocheck
        en: '',
    },

    queryZone: {
        fr: 'Zone de recherche',
        nl: 'Boorzone', // nl tocheck
        en: '',
    },

    queryiedData: {
        fr: 'Données explorées',
        nl: 'Onderzochte gegevens', // nl tocheck
        en: '',
    },

    currentMap: {
        fr: 'Carte courante',
        nl: 'Huidige kaart', // nl tocheck
        en: '',
    },

    // queryHelpText1: {
    //     fr: `Veuillez definir la zone à explorer.
    //     Pour fermer la forme, vous pouvez double-cliquer sur le dernier point.`,
    //     nl: '', // nltodo
    //     en: '',
    // },

    queryHelpText2: {
        fr: `Veuillez sélectionner les couches desquelles vous souhaitez extraire des informations`,
        nl: '', // nltodo
        en: '',
    },

    querySelectAll: {
        fr: 'Tout sélectionner',
        nl: 'Alles selecteren',
        en: 'Select all',
    },

    queryDeselectAll: {
        fr: 'Tout désélectionner',
        nl: 'Alles deselecteren',
        en: 'Deselect all',
    },

    harvestTitle: {
        fr: 'Filtre spatial',
        nl: 'Ruimtelijke filter',
        en: 'Spatial filter',
    },

    harvestResults: {
        fr: 'Résultats de la sélection',
        nl: 'Resultaten van de selectie',
        en: 'Results of the selection',
    },

    harvestInitiatePoint: {
        fr: 'Placer un point',
        nl: 'met een punt', //nl to check
        en: 'with a point',
    },

    harvestInitiateLine: {
        fr: 'Tracer une ligne',
        nl: 'met één regel', //nl to check
        en: 'with a line',
    },

    harvestInitiatePolygon: {
        fr: 'Définir un polygone',
        nl: 'met een veelhoek', //nl to check
        en: 'with a polygon',
    },

    harvestInfoPoint: {
        fr: `Cliquez sur la carte pour créer un point de forage.`,
        nl: `Klik op de kaart om een boorpunt te maken.`,
        en: `Click on the map to create a drilling point.`,
    },

    harvestInfoLine: {
        fr: `Cliquez sur la carte pour tracer des segments.
        Pour valider, cliquez une deuxième fois sur le dernier point.
        Pour tracer une ligne à main levée, appuyez sur Shift (Majuscule) tout en maintenant le clic de la souris enfoncé.`,
        nl: `Klik op de kaart om segmenten te tekenen.
        Om te valideren, klik een tweede keer op het laatste punt.
        Om een lijn uit de vrije hand te tekenen, drukt u op Shift terwijl u de muisklik ingedrukt houdt.`,
        en: `Click on the map to draw segments.
        To validate, click a second time on the last point.
        To draw a freehand line, press Shift while holding down the mouse click.`,
    },

    harvestInfoPolygon: {
        fr: `Cliquez sur la carte pour tracer les sommets du polygone. 
        Pour valider, fermez le polygone en cliquant sur le premier sommet créé.
        Pour tracer une surface à main levée, appuyez sur Shift (Majuscule) tout en maintenant le clic de la souris enfoncé. `,
        nl: `Klik op de kaart om de hoekpunten van de veelhoek te tekenen. 
        Om te valideren, sluit u de veelhoek door op het eerste aangemaakte hoekpunt te klikken.
        Om een oppervlak uit de vrije hand te tekenen, drukt u op Shift terwijl u de muisklik ingedrukt houdt.`,
        en: `Click on the map to draw the vertices of the polygon. 
        To validate, close the polygon by clicking on the first vertex created.
        To draw a freehand surface, press Shift while holding down the mouse click.`,
    },

    multiSelectionHelptext: {
        en: 'Use `SHIFT + CLICK` to select multiple entities.',
        fr: 'Utilisez `SHIFT + CLIC` pour sélectionner plusieurs entités.',
        nl: 'Gebruik `SHIFT + CLICK` om meerdere entiteiten te selecteren.',
    },

    selectedTitle: {
        en: 'Selection',
        fr: 'Sélection',
        nl: 'Selectie',
    },
    // selectedAmount: {
    //     en: 'Number of selected features',
    //     fr: "Nombre d'entités sélectionnées",
    //     nl: 'Aantal geselecteerde kenmerken',
    // },
    selectedAmount: {
        en: ' features',
        fr: ' entités',
        nl: ' kenmerken',
    },

    goToQueryPage: {
        fr: 'Voir le rapport',
        nl: 'Zie het rapport',
    },
    clearSelection: {
        fr: 'Annuler la sélection',
        nl: 'Selectie annuleren', //nl todo
    },
    clearFilter: {
        fr: 'Annuler le filtre',
        nl: 'Selectie annuleren', //nl todo
    },
    spatialFilterHelpText: {
        fr: 'Utilisez le filtre spatial pour explorer les données selon une zone, une ligne ou un point que vous dessinez sur la carte.',
        nl: 'Gebruik het ruimtelijke filter om de gegevens te verkennen op basis van een gebied, lijn of punt dat u op de kaart tekent.', //nl todo
    },

    group: {
        fr: 'Inclu dans le groupe: ',
        nl: 'In groep: ', //nl todo
    },

    // From Metadatas:
    metadataDescription: {
        fr: 'Description',
        nl: 'Beschrijving',
    },
    maintenanceFrequency: {
        fr: 'Fréquence de mise à jour des données',
        nl: 'Frequentie van gegevensupdates', // nl todo
        en: 'frequency of data update',
    },

    continual: {
        fr: 'En continu (plusieurs fois par jour)',
        nl: 'Voortdurend (verschillende keren per dag)',
        en: 'repeatedly and frequently',
    },
    daily: {
        fr: 'Quotidienne',
        nl: 'Dagelijks',
        en: 'daily',
    },
    weekly: {
        fr: 'Hebdomadaire',
        nl: 'Wekelijks',
        en: 'weekly',
    },
    fortnightly: {
        fr: 'Toutes les deux semaines',
        nl: 'Om de twee weken',
        en: 'fortnightly',
    },
    monthly: {
        fr: 'Mensuelle',
        nl: 'Maandelijks',
        en: 'monthly',
    },
    quarterly: {
        fr: 'Tous les 3 mois',
        nl: 'Driemaandelijks',
        en: 'quarterly',
    },
    biannually: {
        fr: 'Biannuelle',
        nl: 'Tweejaarlijks',
        en: 'biannually',
    },
    annually: {
        fr: 'Annuelle',
        nl: 'Jaarlijks',
        en: 'annually',
    },
    asNeeded: {
        fr: 'Si nécessaire',
        nl: 'Indien nodig',
        en: 'as needed',
    },
    irregular: {
        fr: 'Irrégulièrement',
        nl: 'Onregelmatig',
        en: 'irregular',
    },
    notPlanned: {
        fr: 'Non planifiée',
        nl: 'Niet gepland',
        en: 'not planned',
    },
    unknown: {
        fr: 'Inconnue',
        nl: 'Onbekend',
        en: 'unknown',
    },
    filterByCategory: {
        fr: 'Filtrer par thématique',
        nl: 'Filter op thema', //nl tocheck
    },
    applications: {
        fr: 'Applications',
        nl: 'Applicaties', //nl tocheck
    },
    applicationsListTitle: {
        fr: 'Les applications cartographiques',
        nl: 'Cartografische applicaties', //nl done
    },
    mapListTitle: {
        fr: `L'atlas des cartes`,
        nl: 'De kaartenatlas', //nl tocheck
    },
    maps: {
        fr: 'Cartes',
        nl: 'Kaarten', //nl tocheck
    },
    buttonTable: {
        fr: 'Afficher en tableau',
        nl: 'Weergave in tabel', //nl tocheck
        en: 'Table display',
    },
    buttonTile: {
        fr: 'Afficher en blocs',
        nl: 'Weergave in blokken', //nl tocheck
    },
    colTitle: {
        fr: 'Titre',
        nl: 'Titel',
    },
    colDescription: {
        fr: 'Description',
        nl: 'Beschrijving',
    },
    colCategory: {
        fr: 'Thématique',
        nl: 'Categorie', // nl todo
    },
    colDate: {
        fr: 'Date',
        nl: 'Datum',
    },
    colAuthor: {
        fr: 'Auteur',
        nl: 'Auteur', //nl todo
    },
    colStatus: {
        fr: 'Status',
        nl: 'Status',
    },

    draft: {
        fr: 'Version éditable',
        nl: 'Te bewerken versie', // nldone
        en: 'Draft',
    },

    saved: {
        fr: 'Version de sauvegarde',
        nl: 'Opgeslaagd versie', //nl todo
        en: 'Saved version',
    },

    published: {
        fr: 'Version publiée',
        nl: 'Gepubliceerd versie', // nldone
        en: 'Published version',
    },
    dataAccess: {
        fr: '',
        nl: '',
        en: '',
    },
    element: {
        fr: 'éléments',
        nl: 'elementen',
        en: 'elements',
    },
    selectedBaselayersTitle: {
        fr: 'Sélection actuelle',
        nl: 'Actuele selectie',
        en: 'Current selection',
    },
    availableBaselayersTitle: {
        fr: 'Couches disponibles',
        nl: 'Beschikbare lagen',
        en: 'Available layers',
    },
};

type MDB = typeof messages;
export type ViewMessageKey = keyof MDB;

declare module 'sdi/locale' {
    export interface MessageStore {
        view(k: ViewMessageKey): Translated;
    }
}

MessageStore.prototype.view = function (k: ViewMessageKey) {
    return this.getEdited('view', k, () => formatMessage(messages[k]));
};
