/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import * as debug from 'debug';

import {
    IStoreInteractions,
    IReducer,
    IAliasCollection,
    IReducerAsync,
    MessageRecordLang,
    MessageRecord,
    AppConfig,
    AppManifest,
    Term,
    AppUrl,
    IUser,
    HighlightedBaseLayer,
    MaintenanceEvent,
    AppMessage,
    CRSConfig,
} from '../source';
import { Domain } from '../source/io/domain';
import { fromNullable, none, some } from 'fp-ts/lib/Option';
import { checkURLScheme, Collection, Nullable } from '../util';
import { ModalState } from '../components/modal';
import { geocoderDefaultState, GeocoderState } from '../geocoder';
import { Coordinate } from 'ol/coordinate';

const logger = debug('sdi:shape');

export interface IShape {
    'app/force-refresh': number; // to help with the rare components that maintain an internal state, please do not abuse - pm
    'app/codename': string;
    'app/user': IUser | null;
    'app/api-root': string;
    'app/lang': MessageRecordLang;
    'app/languages': MessageRecordLang[];
    'app/csrf': string | null;
    'app/root': string;
    'app/activityToken': string | null;
    'app/apps': AppManifest[];
    'app/urls': AppUrl[];
    'app/fonts': {
        picto: {
            url: string;
            loaded: boolean;
        };
        label: {
            url: string;
            loaded: boolean;
        };
    };
    'app/admin-query': Nullable<number>;
    'app/appselect/visible': boolean;
    'app/focus-id': Nullable<string>;
    'app/collapsible': Collection<boolean>;
    'app/geocoder': GeocoderState;
    'app/geocoder/input': string;
    'app/geocoder/input/coord': Nullable<Coordinate>;
    'app/route': string[];
    'app/select/open/id': Nullable<string>;
    'app/maintenance': MaintenanceEvent[];
    'app/messages': AppMessage[];
    'app/data-crs': CRSConfig;
    'app/map-crs': CRSConfig;

    'data/alias': IAliasCollection;
    'data/component/edited': Collection<MessageRecord>;
    'data/terms': Term[];
    'data/domains': Domain[];
    'data/highlighted-baselayers': HighlightedBaseLayer[];

    'sdi/component/info/modal/status': ModalState;
}

export const defaultShape = (config: AppConfig) => ({
    'app/force-refresh': 0,
    'app/user': config.user,
    'app/api-root': checkURLScheme(config.api),
    'app/csrf': config.csrf,
    'app/root': checkURLScheme(config.root),
    'app/apps': config.apps,
    'app/urls': config.urls,
    'app/fonts': {
        picto: {
            url: config.fonts.picto,
            loaded: false,
        },
        label: {
            url: config.fonts.label,
            loaded: false,
        },
    },
    'app/route': config.args,
    'app/admin-query': config.admin_query,
    'app/lang': 'fr' as MessageRecordLang,
    'app/languages': config.languages,
    'app/activityToken': null,
    'app/appselect/visible': false,
    'app/focus-id': null,
    'app/collapsible': {},
    'app/geocoder': geocoderDefaultState,
    'app/geocoder/input': '',
    'app/geocoder/input/coord': null,
    'app/select/open/id': null,
    'app/maintenance': config.maintenance,
    'app/messages': config.app_messages,
    'app/data-crs': fromNullable(config.dataCrs).getOrElse('EPSG:4326'),
    'app/map-crs': fromNullable(config.mapCrs).getOrElse('EPSG:3857'),

    'data/alias': [],
    'data/component/edited': {},
    'sdi/component/info/modal/status': {},
    'data/terms': [],
    'data/domains': [],
    'data/highlighted-baselayers': config.highlighted_baselayers,
});

interface Observer<K extends keyof IShape> {
    key: K;
    handler(a: Readonly<IShape[K]>): void;
    immediate: boolean;
}

let storeRef: IStoreInteractions<IShape> | null = null;
const getStore = () => fromNullable(storeRef);

const pendingObservers: Observer<keyof IShape>[] = [];

export interface Getter<T> {
    (): T;
}
export interface Setter<T> {
    (h: (a: T) => T): void;
}

const notYetConfigured = (store: IStoreInteractions<IShape>) => {
    pendingObservers.forEach(o => store.observe(o.key, o.handler, o.immediate));

    storeRef = store;
};

export const configure = (store: IStoreInteractions<IShape>) =>
    getStore().foldL(
        () => notYetConfigured(store),
        () => {
            throw new Error('StoreAlreadyConfigured');
        }
    );

/**********
 * Queries
 **********/

const guard = () => {
    throw new Error('DispatchNotConfigured');
};

export const query = <K extends keyof IShape>(key: K): Readonly<IShape[K]> =>
    getStore().foldL(guard, store => store.get(key));

/**
 * The function surely feels like a hack, it is.
 * But TS would insist that the value could be
 * null or udefined even after testing for it not to be.
 */
export const queryOption = <K extends keyof IShape>(key: K) =>
    getStore().chain(store => {
        const value = store.get(key);
        if (value !== null && value !== undefined) {
            return some(
                value as Readonly<Exclude<IShape[K], null | undefined>>
            );
        }
        return none;
    });

export const queryK =
    <K extends keyof IShape>(key: K) =>
    () =>
        query(key);

type SubFn<K extends keyof IShape, T> = (a: Readonly<IShape[K]>) => T;

type ShapeK = keyof IShape;

export const subscribe = <K extends ShapeK, T>(
    key: K,
    fn: SubFn<K, T>,
    ...otherKeys: ShapeK[]
) => {
    let result: T;
    let stall = true;
    observe_(key, () => (stall = true), true);
    otherKeys.forEach(k => observe_(k, () => (stall = true), true));

    return () => {
        if (stall) {
            result = fn(query(key));
            stall = false;
        }
        return result;
    };
};

/**********
 * Events
 **********/

export const dispatch = <K extends keyof IShape>(
    key: K,
    handler: IReducer<IShape, IShape[K]>
): void => getStore().foldL(guard, store => store.dispatch(key, handler));

/**
 * A shortcut for dispatching a replacement value
 *
 * @param key
 * @param value
 */
export const assign = <K extends keyof IShape>(
    key: K,
    value: IShape[K]
): void => getStore().foldL(guard, store => store.dispatch(key, () => value));

export const assignK =
    <K extends keyof IShape>(key: K) =>
    (value: IShape[K]): void =>
        getStore().foldL(guard, store => store.dispatch(key, () => value));

export const dispatchAsync = <K extends keyof IShape>(
    key: K,
    handler: IReducerAsync<IShape, IShape[K]>
): void => getStore().foldL(guard, store => store.dispatchAsync(key, handler));

export const dispatchK =
    <K extends keyof IShape>(key: K) =>
    (handler: IReducer<IShape, IShape[K]>) =>
        dispatch(key, handler);

const observerNotYetConfigured = <K extends keyof IShape>(
    key: K,
    handler: (a: IShape[K]) => void,
    immediate = false
) => {
    pendingObservers.push({ key, handler, immediate });
};

// tslint:disable-next-line:variable-name
const observe_ = <K extends keyof IShape>(
    key: K,
    handler: (a: Readonly<IShape[K]>) => void,
    immediate = false
): void =>
    getStore().foldL(
        () => observerNotYetConfigured(key, handler, immediate),
        store => store.observe(key, handler, immediate)
    );

export const observe = <K extends keyof IShape>(
    key: K,
    handler: (a: Readonly<IShape[K]>) => void
) => observe_(key, handler);

export const makeSetReset = <K extends keyof IShape>(
    key: K,
    val: IShape[K]
): [(value: IShape[K]) => void, () => void] => [
    assignK(key),
    () => assign(key, val),
];

logger('loaded');
