import bbox from '@turf/bbox';
import {
    IMapBaseLayer,
    Feature,
    Properties,
    FeatureCollection,
    DirectGeometryObject,
    getFields,
    FieldsDescriptor,
    MultiPolygon,
} from '../source';
import { MessageKey, tr, fromRecord, formatNumber } from '../locale';
import { Setoid, setoidString, setoidNumber } from 'fp-ts/lib/Setoid';
import {
    some,
    none,
    fromNullable,
    Option,
    fromPredicate,
} from 'fp-ts/lib/Option';
import { P } from '../components/elements';
import { Coordinate } from 'ol/coordinate';
import { index } from 'fp-ts/lib/Array';

export interface Foldable {
    folded: boolean;
}

// export type NullOrUndefined = null | undefined;
export type Nullable<T> = T | null;
export type Undefined<T> = T | Nullable<T> | undefined;

export interface IdentifiedS {
    id: string;
}
export interface IdentifiedN {
    id: number;
}
export type Identified = IdentifiedS | IdentifiedN;

export interface Collection<T, _K extends string = string> {
    [k: string]: T;
}

// an equivalent of `concat`
export const updateCollection = <T>(
    coll: Collection<T>,
    key: string,
    value: T
) => {
    coll[key] = value;
    return coll;
};

export const mapCollection = <T, R>(
    f: (a: string, b: T) => R,
    col: Collection<T>
): R[] => Object.keys(col).map(key => f(key, col[key]));

export const mapCollectionC =
    <T, R>(f: (a: string, b: T) => R) =>
    (col: Collection<T>): R[] =>
        mapCollection(f, col);

export const getCollectionItem = <T>(
    key: string,
    col: Collection<T>
): Option<T> => fromNullable(col[key]);

/**
 * A more granular Partial type.
 * Use case is POSTing a new object without an id
 */
export type PrecisePartial<T, PART extends keyof T> = {
    [K in PART]?: T[K];
} & Pick<T, Exclude<keyof T, PART>>;

export const asArray = <T>(a: T | T[]) => (Array.isArray(a) ? a : [a]);

export const sameArrays = <T>(a: T[], b: T[]) =>
    a.length === b.length && a.every((val, index) => val === b[index]);

export const asMutable = <T>(a: Readonly<T> | T) =>
    JSON.parse(JSON.stringify(a)) as T;

export const getNumber = (s?: string) => {
    if (s) {
        const n = parseFloat(s);
        if (!Number.isNaN(n)) {
            return some(n);
        }
    }
    return none;
};

// concat :: ([a],[a]) -> [a]
const concat = <A>(xs: A[], ys: A[]) => xs.concat(ys);

export const flatten = <T>(xs: T[][]) => xs.reduce(concat, []);
// export const flattenRecursive = <T>(
//     arr: T[] | T[][] | T[][][] | T[][][][],
//     result: T[] = []
// ) => {
//     arr.map(a => {
//         if (Array.isArray(a)) {
//             flattenRecursive(a, result);
//         } else {
//             result.push(a);
//         }
//     });
//     return result;
// };

export const swap = <T>(xs: T[], a: number, b: number) => {
    const temp = xs[a];
    xs[a] = xs[b];
    xs[b] = temp;
    return xs;
};

export const defaultCollection = <T>(): Collection<T> => ({});

export interface IMapBaseLayerTranslated {
    name: string;
    srs: string;
    params: {
        LAYERS: string;
        VERSION: string;
    };
    url: string;
}

export const hashMapBaseLayer = (l: IMapBaseLayer) => {
    return `${fromRecord(l.name)}|${l.url}|${fromRecord(l.params.LAYERS)}`;
};

export const setoidIdentified = <T extends Identified>(): Setoid<T> => ({
    equals: (a: Identified, b: Identified) => a.id === b.id,
});

// export const setoidTime: Setoid<Date> = {
//     equals: (a, b) => a.getTime() === b.getTime()
// };

export const setoidDate: Setoid<Date> = {
    equals: (a, b) =>
        a.getFullYear() === b.getFullYear() &&
        a.getMonth() === b.getMonth() &&
        a.getDate() === b.getDate(),
};

export const uniq =
    <T>(o: Setoid<T>) =>
    (as: T[]): T[] =>
        as.reduce(
            (acc, a) => acc.filter(i => !o.equals(a, i)).concat([a]),
            [] as T[]
        );

export const uniqString = uniq(setoidString);
export const uniqEqual = <T>() => uniq<T>({ equals: (a, b) => a === b });
export const uniqNumber = uniq(setoidNumber);
export const uniqIdentified = <T extends Identified>(as: T[]) =>
    uniq(setoidIdentified<T>())(as);

export const arrayEquals = <T extends string | number | boolean>(
    a: T[],
    b: T[]
) =>
    a.length === b.length &&
    zip(a, b).reduce((acc, v) => acc && v[0] === v[1], true);

export const once = <ARG, RET>(f: (...args: ARG[]) => RET) => {
    type NotDone = {
        tag: 'no';
    };
    type Done = {
        tag: 'yes';
        mem: RET;
    };

    let done: NotDone | Done = { tag: 'no' };

    return (...args: ARG[]): RET => {
        switch (done.tag) {
            case 'no': {
                const mem = f(...args);
                done = {
                    tag: 'yes',
                    mem,
                };
                return mem;
            }
            case 'yes':
                return done.mem;
        }
    };
};

export const condOnce = (f: () => boolean) => {
    let done = false;

    return () => {
        if (!done) {
            done = f();
        }
    };
};

const memoKey = <ARGS extends unknown[]>(...args: ARGS) =>
    args.map(a => `${a}`).join('##');

export const memo = <Args extends unknown[], Ret>(
    f: (...args: Args) => Ret
) => {
    const mem: Collection<Ret> = {};
    const inner = (...args: Args) => {
        const key = memoKey(...args);
        if (!(key in mem)) {
            mem[key] = f(...args);
        }
        return mem[key];
    };
    return inner;
};

// TODO: remove one of this two (nonEmpty vs notEmpty... )
export const nonEmpty = fromPredicate<unknown[]>(ls => ls.length > 0);
export const nonEmptyStr = fromPredicate<string>(s => s.length > 0);

export const notEmpty = <T>(ns: T[] | readonly T[]) =>
    ns.length > 0 ? some(ns) : none;

export const translateMapBaseLayer = (
    l: IMapBaseLayer
): IMapBaseLayerTranslated => ({
    name: fromRecord(l.name),
    srs: l.srs,
    params: {
        LAYERS: fromRecord(l.params.LAYERS),
        VERSION: l.params.VERSION,
    },
    url: l.url,
});

export const uniqIdGen = (prefix = '') => {
    let counter = 0;
    return () => {
        counter += 1;
        return `${prefix}${counter}`;
    };
};

export const uniqId = uniqIdGen('sdi-');

const APP_ID_KEY = '__app_id__';

export const addAppIdToFeature = (f: Feature) => {
    f.properties = {
        [APP_ID_KEY]: uniqId(),
        ...f.properties,
    };
};

const FEATURE_PROPS_BLACKLIST = new Set([APP_ID_KEY]);

export const getFeatureProperties = (f: Feature): Properties => {
    const props = f.properties;
    if (!props) {
        return null;
    }
    return Object.keys(props).reduce<Properties>((acc, k) => {
        if (FEATURE_PROPS_BLACKLIST.has(k)) {
            return acc;
        }
        return {
            [k]: props[k],
            ...acc,
        };
    }, {});
};

export const getFeaturePropOption = <T>(f: Feature, key: string): Option<T> => {
    const props = f.properties;
    if (!props) {
        return none;
    }
    return fromNullable(props[key]);
};

export const getLayerPropertiesKeys = (layer: FeatureCollection): string[] =>
    getFields(layer)
        .map(fields => fields.map(([name, _]) => name))
        .getOrElse(
            index(0, layer.features)
                .chain(feature => fromNullable(feature.properties))
                .map(Object.keys)
                .getOrElse([])
        );

export const getLayerPropertiesKeysForNumbers = (
    layer: FeatureCollection
): string[] =>
    getFields(layer)
        .map(fields =>
            fields.filter(([_, t]) => t === 'number').map(([name, _]) => name)
        )
        .getOrElse(
            index(0, layer.features)
                .chain(feature => fromNullable(feature.properties))
                .map(Object.keys)
                .getOrElse([])
        );

export const getLayerFields = (layer: FeatureCollection) =>
    getFields(layer).alt(
        index(0, layer.features)
            .chain(feature => fromNullable(feature.properties))
            .map(props =>
                Object.keys(props).map<FieldsDescriptor>(key => {
                    const val = props[key];

                    switch (typeof val) {
                        case 'string':
                            return [key, 'string'];
                        case 'number':
                            return [key, 'number'];
                        case 'boolean':
                            return [key, 'boolean'];
                        default:
                            return [key, 'string'];
                    }
                })
            )
    );

// export const getLayerPropertiesKeysFiltered = (
//     fc: FeatureCollection,
//     pred: (a: any) => boolean
// ): string[] => {
//     if (fc.features.length === 0) {
//         return [];
//     }
//     const f = fc.features[0];
//     const props = f.properties;
//     if (!props) {
//         return [];
//     }
//     return Object.keys(props).reduce<string[]>((acc, k) => {
//         if (FEATURE_PROPS_BLACKLIST.has(k) || !pred(props[k])) {
//             return acc;
//         }
//         acc.push(k);
//         return acc;
//     }, []);
// };

// export const splitString = <T extends HTMLTagName>(
//     f: ElementFactory<T>,
//     sep = '\n'
// ) => (s: string) =>
//         s.split(sep).map((ss, index) => f({ key: `split-${index}` }, ss));

export const splitString = (s: string) =>
    s.split('\n').map((ss, index) => P({ key: `split-${index}` }, ss));

export const stringToParagraphs = splitString;

/*
 * implements binary search (recursive)
 *
 * https://en.wikipedia.org/wiki/Binary_search_algorithm
 * Where it's different from general implementation lies in the fact
 * that's the predicate which evaluates rather then numeric comparision.
 * Thus the predicate must know the key.
 *
 * @param min Number minimum value
 * @param max Number maximun value
 * @predicate Function(pivot) a function that evaluates the current mid value a la compareFunction
 * @context Object context to which the predicate is applied
 *
 */

export type BinaryPredicate = (a: number) => number;

export const binarySearch: (
    a: number,
    b: number,
    c: BinaryPredicate
) => number = (min, max, predicate) => {
    const interval = max - min;
    const pivot = min + Math.floor(interval / 2);

    if (max === min) {
        return pivot;
    } else if (max < min) {
        // throw (new Error('MaxLowerThanMin'));
        return pivot;
    }

    if (predicate(pivot) > 0) {
        return binarySearch(min, pivot, predicate);
    } else if (predicate(pivot) < 0) {
        return binarySearch(pivot + 1, max, predicate);
    }
    return pivot;
};

/**
 *
 * At this point, I'm tired of of checking
 * both at all times when prod brakes.
 * Let's verbose!
 *
 */
export const isNotNullNorUndefined = <T>(x: Undefined<T>): x is T =>
    x !== undefined && x !== null;

export const filterNotNull = <T>(xs: Nullable<T>[]): T[] => {
    const r: T[] = [];
    for (let i = 0; i < xs.length; i += 1) {
        const v = xs[i];
        if (isNotNullNorUndefined(v)) {
            r.push(v);
        }
    }
    return r;
};

const withUnit =
    (k: MessageKey) =>
    (value: number, tf = 0) =>
        `${formatNumber(parseFloat(value.toFixed(tf)))}\u00A0${tr.core(k, {
            value,
        })}`;

export const withEuro = withUnit('unitEuro');
export const withEuroExclVAT = withUnit('unitEuroExclVAT');
export const withEuroInclVAT = withUnit('unitEuroInclVAT');
export const withEuroY = withUnit('unitEuroY');
export const withEuroY10 = withUnit('unitEuroY10');
export const withEuroY25 = withUnit('unitEuroY25');
export const withKWc = withUnit('unitKWc');
export const withTonsCO2 = withUnit('unitTonsCO2');
export const withTCO2 = withUnit('unitTCO2');
export const withTCO2Y = withUnit('unitTCO2Y');
export const withTCO2Y10 = withUnit('unitTCO2Y10');
export const withTCO2Y25 = withUnit('unitTCO2Y25');
export const withYear = withUnit('unitYear');
export const withMeter = withUnit('unitM');
export const withM2 = withUnit('unitM2');
export const withPercent = withUnit('unitPercent');
export const withKWh = withUnit('unitKWh');
export const withKWhY = withUnit('unitKWhY');
export const withLiter = withUnit('unitLiter');
export const withLiterDay = withUnit('unitLiterDay');

export const getPathElements = (p: string) =>
    p.split('/').filter(e => e.length > 0);

export const ensureArray = <T>(a: T | T[]): T[] => {
    if (Array.isArray(a)) {
        return a;
    }
    if (a === undefined) {
        return [];
    }
    return [a];
};

export const parseDate = (s: string) => {
    const d = Date.parse(s);
    return Number.isNaN(d) ? none : some(new Date(d));
};
export const datetime8601 = (d: Date) =>
    d.toISOString().slice(0, 16).replace('T', ' ');
export const date8601 = (d: Date) => datetime8601(d).slice(0, 10);

export const dateISOFormat = (d: Date) =>
    `${d.getFullYear()}-${fmtDateNb(d.getMonth() + 1)}-${fmtDateNb(
        d.getDate()
    )}`; // this does NOT considerate timezone...

export const fmtDateNb = (n: number) => (n < 10 ? `0${n}` : `${n}`);
export const datetimeBEFormated = (d: Date): string => `${fmtDateNb(
    d.getDate()
)}/${fmtDateNb(d.getMonth() + 1)}/${d.getFullYear()}
    ${fmtDateNb(d.getHours())}:${fmtDateNb(d.getMinutes())}`;

export const encodeUrlQuery = <D extends string | number | boolean>(
    o: Collection<D>
) => mapCollection((k, v) => `${k}=${encodeURIComponent(v)}`, o).join('&');

// we put it there as @turf/center doesn't come with types
export const center = (geojson: DirectGeometryObject): Coordinate => {
    const ext = bbox(geojson);
    const x = (ext[0] + ext[2]) / 2;
    const y = (ext[1] + ext[3]) / 2;
    return [x, y];
};

// from https://github.com/behnammodi/polyfill/blob/master/string.polyfill.js
export const padStart = (
    source: string,
    targetLength: number,
    padString = ' '
) => {
    targetLength = targetLength >> 0; // floor if number or convert non-number to 0;
    padString = String(typeof padString !== 'undefined' ? padString : ' ');
    if (source.length > targetLength) {
        return String(source);
    } else {
        targetLength = targetLength - source.length;
        if (targetLength > padString.length) {
            padString += padString.repeat(targetLength / padString.length); // append to original to ensure we are longer than needed
        }
        return padString.slice(0, targetLength) + String(source);
    }
};

export const makeNumberList = (start: number, end: number) =>
    // tslint:disable-next-line: prefer-array-literal
    new Array(end + 1 - start).fill(0).map((_, index) => start + index);

export const tryNumber = (n: unknown) => {
    if (typeof n === 'number' && !Number.isNaN(n)) {
        return some(n);
    } else if (typeof n === 'string') {
        const pn = parseFloat(n);
        if (Number.isNaN(pn)) {
            return none;
        }
        return some(pn);
    }
    return none;
};

export const tryBoolean = (n: unknown) => {
    if (typeof n === 'boolean') {
        return some(n);
    }
    return none;
};

export const tryString = (n: unknown) => {
    if (typeof n === 'string') {
        return some(n);
    }
    return none;
};

export const tryTuple1 = <T>(a: T[]): Option<[T]> => {
    if (a.length === 1) {
        return some([a[0]]);
    } else {
        return none;
    }
};
export const tryTuple2 = <T>(a: T[]): Option<[T, T]> => {
    if (a.length === 2) {
        return some([a[0], a[1]]);
    } else {
        return none;
    }
};
export const tryTuple3 = <T>(a: T[]): Option<[T, T, T]> => {
    if (a.length === 3) {
        return some([a[0], a[1], a[2]]);
    } else {
        return none;
    }
};
export const tryTuple4 = <T>(a: T[]): Option<[T, T, T, T]> => {
    if (a.length === 4) {
        return some([a[0], a[1], a[2], a[3]]);
    } else {
        return none;
    }
};

export interface RangeMin {
    min: number;
}

export interface RangeMax {
    max: number;
}

// We postfix to avoid name collision
export type RangeT = RangeMax | RangeMin | (RangeMax & RangeMin);

export const range = (min: number, max: number): RangeMax & RangeMin => ({
    min,
    max,
});

export const rangeMin = (min: number): RangeMin => ({ min });

export const rangeMax = (max: number): RangeMax => ({ max });

export const tryStringRange = (r: RangeT) => {
    if ('min' in r && 'max' in r) {
        return fromPredicate<string>(
            s => s.length > r.min && s.length <= r.max
        );
    } else if ('max' in r) {
        return fromPredicate<string>(s => s.length <= r.max);
    } else {
        return fromPredicate<string>(s => s.length > r.min);
    }
};

export const tryMultiPolygon = (
    geom: DirectGeometryObject
): Option<MultiPolygon> => {
    switch (geom.type) {
        case 'MultiPolygon':
            return some(geom);
        case 'Polygon':
            return none; // TODO: try ay least
        default:
            return none;
    }
};

// too early
// export function* zipIterator<T0, T1>(a: T0[], b: T1[]): IterableIterator<[T0, T1]> {
//     const max = Math.min(a.length, b.length)
//     for (let i = 0; i < max; i += 1) {
//         yield [a[i], b[i]]
//     }
// }

export const zip = <T0, T1>(
    a: T0[] | Readonly<T0[]>,
    b: T1[] | Readonly<T1[]>
): [T0, T1][] => {
    const max = Math.min(a.length, b.length);
    const result: [T0, T1][] = new Array(max);
    for (let i = 0; i < max; i += 1) {
        result[i] = [a[i], b[i]];
    }
    return result;
};

export const zip3 = <T0, T1, T2>(a: T0[], b: T1[], c: T2[]): [T0, T1, T2][] => {
    const max = Math.min(a.length, b.length, c.length);
    const result: [T0, T1, T2][] = new Array(max);
    for (let i = 0; i < max; i += 1) {
        result[i] = [a[i], b[i], c[i]];
    }
    return result;
};

export const min = (values: number[]) =>
    index(0, values).map(init =>
        values.reduce((acc, val) => (val < acc ? val : acc), init)
    );

export const max = (values: number[]) =>
    index(0, values).map(init =>
        values.reduce((acc, val) => (val > acc ? val : acc), init)
    );

export const minAndMax = (values: number[]) =>
    index(0, values).map(init =>
        values.reduce<[number, number]>(
            ([min, max], val) => [val < min ? val : min, val > max ? val : max],
            [init, init]
        )
    );

export const dbg = <T>(val: T, name = '??') => {
    console.log(`dbg[${name}]>> `, val);
    return val;
};

export const getPercentile = (values: number[], percent: number) => {
    if (values.length > 0) {
        const k = (values.length - 1) * percent;
        const f = Math.floor(k);
        const c = Math.ceil(k);
        if (f == c) {
            return some(values[k]);
        }
        const d0 = values[f] * (c - k);
        const d1 = values[c] * (k - f);
        return some(d0 + d1);
    } else {
        return none;
    }
};

const absoluteURLRegex = new RegExp('^https?:');
/**
 * checkScheme ensures that URL has the same scheme than the location
 * fixes Mixed Content when getting URLs from a proxied server
 * @param url
 */
export const checkURLScheme = (url: string): string => {
    if (!absoluteURLRegex.test(url)) {
        return url; // browser will take care
    }
    const lp = window.location.protocol;
    if ('http:' === lp || url.slice(0, lp.length) === lp) {
        return url; // we're on the same page
    }

    // we're asking for http while we're on https
    return 'https' + url.slice(4);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const noop = (..._args: unknown[]) => void 0;

export const partitionArray = <T>(xs: T[], f: (x: T) => boolean) => {
    const left: T[] = [];
    const right: T[] = [];
    xs.forEach(x => (f(x) ? left.push(x) : right.push(x)));
    return [left, right];
};

export const toDataURL = (f: File) => {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            const result = reader.result;
            if (typeof result === 'string') {
                resolve(result);
            } else {
                reject();
            }
        };
        reader.onerror = reject;
        reader.onabort = reject;
        reader.readAsDataURL(f);
    });
};

export const formatNumberDE = (n: number) =>
    new Intl.NumberFormat('de-DE', {}).format(n);
