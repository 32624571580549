/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import * as debug from 'debug';

import {
    DETAILS,
    DIV,
    H2,
    H3,
    LABEL,
    NodeOrOptional,
    SPAN,
    SUMMARY,
} from 'sdi/components/elements';
import tr, { fromRecord } from 'sdi/locale';
import { IMapBaseLayer } from 'sdi/source';

import {
    getBaseLayersForService,
    getBaseLayerServices,
    getCurrentBaseLayerNamesInService,
    getCurrentBaseLayerNames,
    findBaseLayer,
} from '../../queries/app';
import {
    addMapBaseLayerInService,
    delMapBaseLayer,
    moveMapBaseLayerDown,
    moveMapBaseLayerUp,
} from '../../events/app';
import { makeIcon } from '../button';
import { catOptions } from 'fp-ts/lib/Array';
import { noop } from 'sdi/util';
import { nameToString } from 'sdi/components/button/names';

const logger = debug('sdi:webservices');

const buttonUp = makeIcon('move-up', 3, 'arrow-up', {
    position: 'top-left',
    text: () => tr.core('move-up'),
});

const buttonDown = makeIcon('move-up', 3, 'arrow-down', {
    position: 'top-left',
    text: () => tr.core('move-down'),
});

const buttonDelete = makeIcon('remove', 3, 'times', {
    position: 'top-left',
    text: () => tr.core('remove'),
});

const renderSelectedActions = (
    codename: string,
    isFirst: boolean,
    isLast: boolean
) =>
    DIV(
        'baselayer-selected-actions',
        buttonDelete(() => delMapBaseLayer(codename)),
        isFirst
            ? buttonUp(noop, 'disabled')
            : buttonUp(() => moveMapBaseLayerUp(codename)),
        isLast
            ? buttonDown(noop, 'disabled')
            : buttonDown(() => moveMapBaseLayerDown(codename))
    );

const renderSelectedBaseLayer =
    (actions: NodeOrOptional) => (baseLayer: IMapBaseLayer) =>
        DIV(
            'base-layer-selected',
            DIV(
                'label-wrapper',
                SPAN('fa icon', nameToString('check')),
                LABEL('', fromRecord(baseLayer.name))
            ),
            actions
        );

// const renderAddBaselayerBtn = makeLabelAndIcon('add', 2, 'check', () =>
//     tr.core('add')
// );

const renderBaseLayer = (
    add: (layer: string) => void,
    selected: boolean,
    baseLayer: IMapBaseLayer
) =>
    selected
        ? DIV(
              {
                  className: 'base-layer-item disabled',
              },
              SPAN('fa icon', nameToString('check')),
              LABEL('', fromRecord(baseLayer.name))
          )
        : DIV(
              {
                  className: 'base-layer-item',
                  onClick: () => add(baseLayer.codename),
              },
              SPAN('fa icon', nameToString('check')),
              LABEL('', fromRecord(baseLayer.name))
          );

const renderLayerList = (service: string, selected: string[]) =>
    DIV(
        'base-layer-list',
        ...getBaseLayersForService(service).map(bl =>
            renderBaseLayer(
                addMapBaseLayerInService(service),
                selected.includes(bl.codename),
                bl
            )
        )
    );

const renderService = (service: string) => {
    return DETAILS(
        { className: 'webservice', key: `renderService-${service}` },
        SUMMARY('', DIV({ className: 'webservice-name' }, service)),
        renderLayerList(service, getCurrentBaseLayerNamesInService(service))
    );
};

const renderSelected = () =>
    DIV(
        'selected-base-layers',
        H3('', tr.view('selectedBaselayersTitle')),
        ...catOptions(
            getCurrentBaseLayerNames().map((codename, idx, names) =>
                findBaseLayer(codename).map(
                    renderSelectedBaseLayer(
                        renderSelectedActions(
                            codename,
                            idx === 0,
                            idx === names.length - 1
                        )
                    )
                )
            )
        )
    );

const webservices = () => {
    return DIV(
        'sidebar-main tool wms-picker',
        H2({}, tr.view('wmsSwitch')),
        DIV({}, tr.view('helptext:wmsSwitchTool')),
        DIV(
            'tool-body',
            renderSelected(),
            DIV(
                'available-base-layers',
                H3('', tr.view('availableBaselayersTitle')),
                getBaseLayerServices().map(renderService)
            )
        )
    );
};

export default webservices;

logger('loaded');
