/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import * as debug from 'debug';

import { __forceRefreshState, getRelativeRoot } from 'sdi/app';
import tr from 'sdi/locale';
import { DIV, H2, SPAN, H3, BUTTON } from 'sdi/components/elements';

import { getCurrentMap } from '../../queries/app';
import { getView } from '../../queries/map';
import { renderRadioIn } from 'sdi/components/input';
import { EmbedProfile } from 'sdi/source';

const logger = debug('sdi:tool-share');
const hasClipboard = !!document.execCommand;
const location = document.location;
const origin = location.origin;
// const path = location.pathname;

const copyToClipBoard = (s: string) => {
    if (!hasClipboard) {
        return SPAN({});
    }

    return BUTTON(
        {
            className: 'copy',
            onClick: () => {
                const ta = document.createElement('textarea');
                ta.style.position = 'absolute';
                ta.style.width = '10px';
                ta.style.left = '-10px';
                document.body.appendChild(ta);
                ta.value = s;
                ta.setAttribute('readonly', '');
                ta.select();
                ta.setSelectionRange(0, s.length);
                const copied = document.execCommand('Copy');
                document.body.removeChild(ta);
                logger(`text copied: ${copied}`);
            },
        },
        tr.view('copy'),
        SPAN({ className: 'copied' }, tr.view('copied'))
    );
};

const makeCopyable = (value: string) => {
    return DIV(
        { className: 'text-copy' },
        copyToClipBoard(value),
        SPAN({ className: 'text' }, value)
    );
};

//radio button with text out, and only two choices
const { setProfile, getProfile } = (() => {
    let profile: EmbedProfile = 'simple';
    const setProfile = (key: EmbedProfile) => {
        profile = key;
    };
    const getProfile = () => profile;
    return { setProfile, getProfile };
})();

const selectProfile = (p: EmbedProfile) => {
    setProfile(p);
    __forceRefreshState();
};

const profileSwitch = renderRadioIn(
    'profile-switch',
    (p: Exclude<EmbedProfile, 'fancy'>) => tr.core(p),
    selectProfile,
    'radio'
);

const render = () => {
    const { zoom, center } = getView();

    const x = Math.floor(center[0]);
    const y = Math.floor(center[1]);
    const mapId = getCurrentMap();
    const url = `${origin}${getRelativeRoot()}view/${mapId}`;
    const viewUrl = `${url}/${x}/${y}/${zoom}`;
    const embedUrl = `${origin}${getRelativeRoot()}embed/${mapId}`;
    const viewEmbedUrl = `${embedUrl}/${x}/${y}/${zoom}`;
    const profileUrl = `?profile=${getProfile()}`;

    // FIXME - embed in general
    const iframeExampleWithView = `<!-- ${tr.view('embedCommentSnippet1')}  -->
        <!-- ${tr.view('embedCommentSnippet2')}  -->
    <div id="iFrameAtlas">
        <style scoped>
            #iFrameAtlas {
                position: relative;
                width: 100%;
                padding-bottom: 55%;
            }

            @media (orientation:portrait) {
                #iFrameAtlas {
                    padding-bottom: initial;
                    height: 200vw;
                    max-height: 85vh;
                }
            }
        </style>
        <iframe src="${viewEmbedUrl}${profileUrl}" width="100%" height="100%"
            style="position:absolute;" frameborder="0"></iframe>
    </div>`;

    const iframeExampleWithoutView = `<!-- ${tr.view(
        'embedCommentSnippet1'
    )}  -->
        <!-- ${tr.view('embedCommentSnippet2')}  -->
    <div id="iFrameAtlas">
        <style scoped>
            #iFrameAtlas {
                position: relative;
                width: 100%;
                padding-bottom: 55%;
            }

            @media (orientation:portrait) {
                #iFrameAtlas {
                    padding-bottom: initial;
                    height: 200vw;
                    max-height: 85vh;
                }
            }
        </style>
        <iframe src="${embedUrl}${profileUrl}" width="100%" height="100%"
            style="position:absolute;" frameborder="0"></iframe>
    </div>`;

    return DIV(
        { className: 'sidebar-main tool-group share-embed' },
        DIV(
            { className: 'tool share' },
            H2({}, tr.view('sharingTools')),
            DIV({}, tr.view('helptext:share')),
            DIV(
                { className: 'tool-body' },
                H3({}, tr.view('mapLink')),
                makeCopyable(url),
                H3({}, tr.view('mapLinkWithView')),
                makeCopyable(viewUrl)
            )
        ),
        DIV(
            { className: 'tool embed' },
            H2({}, tr.view('embed')),
            DIV({}, tr.view('helptext:embed')),
            profileSwitch(['simple', 'all-tools'], getProfile()),
            DIV(
                { className: 'tool-body' },
                H3({}, tr.view('mapEmbed')),
                makeCopyable(iframeExampleWithoutView),
                H3({}, tr.view('mapEmbedWithView')),
                makeCopyable(iframeExampleWithView)
            )
        )
    );
};

export default render;

logger('loaded');
