import Map from 'ol/Map';
import Collection from 'ol/Collection';
import Feature, { FeatureLike } from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import LineString from 'ol/geom/LineString';
import Polygon from 'ol/geom/Polygon';
import * as interaction from 'ol/interaction';
import Vector from 'ol/layer/Vector';
import SourceVector from 'ol/source/Vector';
import { DrawEvent } from 'ol/interaction/Draw';
import Snap from 'ol/interaction/Snap';
import Style from 'ol/style/Style';

import { MeasureOptions, withInteraction, InteractionMeasure } from '..';
import { VectorLayer } from '../map';
import { fromNullable } from 'fp-ts/lib/Option';
import Stroke from 'ol/style/Stroke';
import Fill from 'ol/style/Fill';

const lineStyle: Style[] = [
    new Style({
        stroke: new Stroke({
            width: 5,
            color: 'white',
        }),
    }),
    new Style({
        stroke: new Stroke({
            width: 2,
            color: '#3FB2FF',
        }),
    }),
];

const polygonStyle: Style[] = [
    new Style({
        fill: new Fill({
            color: 'rgba(255,255,255,0.3)',
        }),
        stroke: new Stroke({
            width: 5,
            color: 'white',
        }),
    }),
    new Style({
        stroke: new Stroke({
            width: 2,
            color: '#3FB2FF',
        }),
    }),
];

const style = (kind: 'line' | 'poly', _ft: FeatureLike): Style[] | void => {
    switch (kind) {
        case 'poly':
            return polygonStyle;
        case 'line':
            return lineStyle;
    }
};

// measure
const measureHandlers = ({
    updateMeasureCoordinates,
    stopMeasuring,
    source,
}: MeasureOptions & { source: SourceVector<Geometry> }) => {
    const startMeasureLength = (e: DrawEvent) => {
        const feature: Feature<Geometry> = e.feature;
        const line = <LineString>feature.getGeometry();
        line.on('change', event => {
            console.log('measure:change', event.type);
            updateMeasureCoordinates(line.getCoordinates());
        });
    };

    const stopMeasureLength = () => {
        clearSource();
        stopMeasuring();
    };

    const startMeasureArea = (e: DrawEvent) => {
        const feature: Feature<Geometry> = e.feature;
        fromNullable(feature.getGeometry()).map((polygon: Polygon) => {
            polygon.on('change', () =>
                fromNullable(polygon?.getLinearRing(0)?.getCoordinates()).map(
                    updateMeasureCoordinates
                )
            );
        });
    };

    const stopMeasureArea = () => {
        clearSource();
        stopMeasuring();
    };

    const clearSource = () => source.clear();

    return {
        clearSource,
        startMeasureLength,
        stopMeasureLength,
        startMeasureArea,
        stopMeasureArea,
    };
};

export const measure = (options: MeasureOptions) => {
    const measureSource = new SourceVector();
    const measureLayer = new Vector({
        source: measureSource,
        style: feature =>
            style(measureLength.getActive() ? 'line' : 'poly', feature),
    });
    const snapSource = new SourceVector();
    const snapInteraction = new Snap({
        edge: false,
        source: snapSource,
        pixelTolerance: options.snap ? options.snap.pixelTolerance : 10,
    });

    const measureLength = new interaction.Draw({
        type: 'LineString',
        source: measureSource,
    });
    const measureArea = new interaction.Draw({
        type: 'Polygon',
        source: measureSource,
    });

    const { startMeasureLength, startMeasureArea, clearSource } =
        measureHandlers({ ...options, source: measureSource });

    measureLength.on('drawstart', startMeasureLength);
    measureLength.on('drawend', clearSource);
    measureArea.on('drawstart', startMeasureArea);
    measureArea.on('drawend', clearSource);

    const updateSnap = () => {
        const snap = options.snap;
        if (snap !== undefined) {
            fromNullable(snapInteraction.getMap())
                .chain(map =>
                    fromNullable(
                        map
                            .getAllLayers()
                            .find(layer => layer.get('id') === snap.layer())
                    )
                )
                .chain(layer => fromNullable(layer.getSource()))
                .map((source: SourceVector) => {
                    snapSource.clear();
                    snapSource.addFeatures(source.getFeatures());
                });
        }
    };

    const isMeasuring = () =>
        measureLength.getActive() || measureArea.getActive();

    const update = withInteraction<InteractionMeasure>(
        'measure',
        ({ state }) => {
            if (!isMeasuring()) {
                measureSource.clear();
            } else if (state.coordinates.length === 0) {
                measureSource.clear();
                measureArea.abortDrawing();
                measureLength.abortDrawing();
            }
            switch (state.geometryType) {
                case 'LineString':
                    measureLength.setActive(true);
                    measureArea.setActive(false);
                    break;
                case 'Polygon':
                    measureLength.setActive(false);
                    measureArea.setActive(true);
                    break;
            }
            updateSnap();
        },
        () => {
            measureSource.clear();
            measureLength.setActive(false);
            measureArea.setActive(false);
        }
    );

    const init = (map: Map, layers: Collection<VectorLayer>) => {
        layers.push(measureLayer);
        map.addInteraction(measureLength);
        map.addInteraction(measureArea);
        map.addInteraction(snapInteraction);
    };

    return { init, update };
};
