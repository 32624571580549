/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import { getAppUrl } from '../app';
import { fromRecord } from '../locale';
import { DIV, SPAN, A } from './elements';

const renderUrl = (name: string) =>
    getAppUrl(name).map(url =>
        A(
            { className: 'footerLink', href: fromRecord(url.url) },
            fromRecord(url.label)
        )
    );

const csLink = SPAN(
    { className: 'cs-link', title: 'cartostation' },
    A({ href: 'http://cartostation.com', target: '_blank' }, `cartostation`)
);

// const acLink =  (
//     SPAN({className:'ac-link', title: 'atelier cartographique' },
//         A({ href: 'http://atelier-cartographique.be', target: '_blank' }, `atelier cartographique`)));

const csacCredits = SPAN(
    'csac-credits',
    csLink
    // '©',
    // acLink,
);

const footerInfos = () =>
    SPAN('footer-infos', renderUrl('wcag'), renderUrl('contact'));

const render = () => DIV({ className: 'footer' }, footerInfos(), csacCredits);

export default render;
