/*
 *  Copyright (C) 2023 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import { query, queryK } from '../shape';
import {
    fromEither,
    fromNullable,
    fromPredicate,
    none,
    some,
} from 'fp-ts/lib/Option';
import { index } from 'fp-ts/lib/Array';
import { IAliasCollection, IMapInfo, appDisplay, appName } from '../source';
import { fromRecord } from '../locale';
import { identity } from 'fp-ts/lib/function';
import { MessageRecordLang, MessageRecordLangIO } from '../source/io/message';
import { checkURLScheme } from '../util';
import * as version from '../../../version.json';
import * as debug from 'debug';
const logger = debug('sdi:app/queries');

export const getCurrentAppCodename = queryK('app/codename');

export const getUserData = () => fromNullable(query('app/user'));
export const getUserId = () => getUserData().map(u => u.id);

export const getUserIdAstNumber = () =>
    getUserId().chain(id =>
        Number.isNaN(parseInt(id, 10)) ? none : some(parseInt(id, 10))
    );

export const isUserLogged = () => getUserId().isSome();
export const isUserMapPred = fromPredicate((map: IMapInfo) =>
    fromNullable(map.user)
        .chain(mapUser =>
            getUserIdAstNumber().map(currentUser => currentUser === mapUser)
        )
        .getOrElse(false)
);

export const getApiUrl = (path: string) =>
    checkURLScheme(`${query('app/api-root')}${path}`);

export const getLang = () => query('app/lang');
export const getLanguages = () => query('app/languages') as MessageRecordLang[];

export const getCSRF = () => fromNullable(query('app/csrf'));

export const getRoute = () =>
    query('app/route')
        .map(frag => frag.trim())
        .filter(frag => frag.length > 0);

export const getRoot = () => checkURLScheme(query('app/root'));

export const getRelativeRoot = () => {
    const parsed = new URL(getRoot());
    return parsed.pathname;
};

export const getRootUrl = (path: string) => getRoot() + path;

export const getActivityToken = () => fromNullable(query('app/activityToken'));

// const blackList = ['dashboard', 'login'];

export const getApps = () =>
    query('app/apps').filter(a => appDisplay(a) !== 'notInMenu');

export const getAppManifest = (codename: string) =>
    fromNullable(query('app/apps').find(a => appName(a) === codename));

export const getAppUrl = (urlName: string) =>
    fromNullable(query('app/urls').find(({ name }) => urlName === name));

const getAliasInDictOption = (dict: Readonly<IAliasCollection>, k: string) =>
    fromNullable(dict.find(alias => alias.select === k)).map(alias =>
        fromRecord(alias.replace)
    );

export const getAliasRaw = (k: string) =>
    fromNullable(query('data/alias')).chain(dict =>
        fromNullable(dict.find(alias => alias.select === k))
    );

export const getAliasOption = (k: string) =>
    fromNullable(query('data/alias')).chain(dict =>
        getAliasInDictOption(dict, k)
    );

export const getAlias = (k: string) => getAliasOption(k).fold(k, identity);

export const getTermList = queryK('data/terms');

export const findTerm = (id: number) =>
    fromNullable(getTermList().find(t => t.id === id));

export const getAppListStatus = () => query('app/appselect/visible');

export const getFocusId = () => query('app/focus-id');

export const getCollapsible = (name: string) =>
    fromNullable(query('app/collapsible')[name]).getOrElse(false);

export const getParameterLang = () => {
    try {
        const loc = document.location;
        const params = new URL(loc.href).searchParams;
        return fromEither(MessageRecordLangIO.decode(params.get('lang')));
    } catch (e) {
        return none;
    }
};

export const isSelectOpen = (id: string) =>
    fromNullable(query('app/select/open/id'))
        .map(i => i === id)
        .getOrElse(false);

export const getReadMore = (name: string) =>
    fromNullable(query('app/collapsible')[name]).getOrElse(false);

// export const pictoFontName = 'cs-picto';
export const pictoFontName = 'ForkAwesome'; // help smoothing transition
export const labelFontName = 'cs-label';

export const isFontLoaded = (which: 'picto' | 'label') =>
    query('app/fonts')[which].loaded;

type Version = {
    major: number;
    minor: number;
    patch: number;
};

export const getVersion = () => version as Version;

export const getVersionString = () => {
    const { major, minor, patch } = getVersion();
    return `${major}.${minor}.${patch}`;
};

export const getMaintenanceEvent = () =>
    index(0, query('app/maintenance').map(identity));

export const getAppMessage = (app: string) => {
    logger(app);
    return fromNullable(query('app/messages').find(am => am.app === app)).map(
        m => {
            logger(fromRecord(m.message));
            return m;
        }
    );
};

export const getMapCrs = queryK('app/map-crs');

export const getDataCrs = queryK('app/data-crs');

logger('loaded');
