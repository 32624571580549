import {
  PartialSpec,
  TemplateSpec,
  TemplateCollection,
} from "view/src/components/print/template";

const makeSpec = (s: PartialSpec): TemplateSpec => ({
  fontSize: 10,
  strokeWidth: 1,
  textAlign: "left",
  color: "black",
  ...s,
});

export const templates: TemplateCollection = {
  "a3/portrait": { resolution: 200 },
  "a3/landscape": { resolution: 200 },
  "a4/portrait": {
    resolution: 200,
    title: makeSpec({
      rect: { x: 132, y: 8, width: 70, height: 120 },
      textAlign: "left",
      fontSize: 22,
      color: "#562821",
    }),

    map: makeSpec({
      rect: { x: 8, y: 8, width: 120, height: 120 },
      color: "#562821",
      strokeWidth: 0.1,
    }),

    legend: makeSpec({ rect: { x: 8, y: 138, width: 194, height: 146 } }),
    legendItem: makeSpec({
      rect: { x: 0, y: 0, width: 62, height: 5 },
      fontSize: 8,
    }),

    attribution: makeSpec({
      rect: { x: 10, y: 124, width: 110, height: 10 },
      fontSize: 5.5,
    }),

    north: makeSpec({
      rect: { x: 120, y: 120, width: 6, height: 6 },
      strokeWidth: 0.5,
    }),

    scaleline: makeSpec({
      rect: { x: 76, y: 118, width: 40, height: 12 },
      strokeWidth: 0.5,
      fontSize: 8,
    }),

    credits: makeSpec({
      rect: { x: 132, y: 122, width: 17, height: 30 / 2 },
      fontSize: 5.5,
      textAlign: "left",
    }),

    logo: makeSpec({ rect: { x: 115, y: 277, width: 80, height: 5 } }),
  },

  "a4/landscape": {
    resolution: 200,
    title: makeSpec({
      rect: { x: 6, y: 8, width: 280, height: 20 },
      textAlign: "left",
      fontSize: 22,
      color: "#562821",
    }),

    legend: makeSpec({ rect: { x: 190, y: 24, width: 102, height: 165 } }),

    legendItem: makeSpec({
      rect: { x: 0, y: 0, width: 50, height: 5 },
      fontSize: 8,
    }),

    map: makeSpec({
      rect: { x: 6, y: 24, width: 180, height: 180 },
      color: "#562821",
      strokeWidth: 0.1,
    }),

    attribution: makeSpec({
      rect: { x: 8, y: 200, width: 180, height: 10 },
      fontSize: 5.5,
    }),

    north: makeSpec({
      rect: { x: 180, y: 196, width: 6, height: 6 },
      strokeWidth: 0.5,
    }),

    scaleline: makeSpec({
      rect: { x: 137, y: 193, width: 40, height: 12 },
      strokeWidth: 0.5,
      fontSize: 8,
    }),

    credits: makeSpec({
      rect: { x: 190, y: 198, width: 17, height: 10 },
      fontSize: 5.5,
      textAlign: "left",
    }),
    logo: makeSpec({ rect: { x: 210, y: 200, width: 80, height: 5 } }),
  },

  "a0/portrait": {
    resolution: 100,
    title: makeSpec({
      rect: { x: 30, y: 30, width: 781, height: 104 },
      textAlign: "left",
      fontSize: 96,
      color: "#562821",
    }),

    map: makeSpec({
      rect: { x: 30, y: 134, width: 781, height: 781 },
      color: "#562821",
      strokeWidth: 0.1,
    }),

    legend: makeSpec({
      rect: { x: 30, y: 781 + 144, width: 781, height: 210 },
    }),
    legendItem: makeSpec({
      rect: { x: 0, y: 0, width: 272, height: 20 },
      fontSize: 24,
    }),

    attribution: makeSpec({
      rect: { x: 37, y: 134 + 771 - 5, width: 500, height: 40 },
      fontSize: 22,
    }),

    north: makeSpec({
      rect: { x: 787, y: 134 + 750 + 12, width: 15, height: 15 },
      strokeWidth: 1,
    }),

    scaleline: makeSpec({
      rect: { x: 577, y: 134 + 745 + 10, width: 200, height: 27 },
      strokeWidth: 1,
      fontSize: 22,
    }),

    credits: makeSpec({
      rect: { x: 520, y: 1135, width: 65, height: 40 },
      fontSize: 22,
      textAlign: "left",
    }),

    logo: makeSpec({ rect: { x: 619, y: 1149, width: 190, height: 12 } }),
  },

  "a0/landscape": {
    resolution: 150,
    title: makeSpec({
      rect: { x: 30 + 781 + 20, y: 30, width: 328, height: 104 },
      textAlign: "left",
      fontSize: 96,
      color: "#562821",
    }),

    map: makeSpec({
      rect: { x: 30, y: 30, width: 781, height: 781 },
      color: "#562821",
      strokeWidth: 0.1,
    }),

    legend: makeSpec({
      rect: {
        x: 30 + 781 + 20,
        y: 30 + 104 + 15,
        width: 328,
        height: 841 - 30 - 104 - 15 - 15 - 40 - 30,
      },
    }),
    legendItem: makeSpec({
      rect: { x: 0, y: 0, width: 272, height: 20 },
      fontSize: 24,
    }),

    attribution: makeSpec({
      rect: { x: 37, y: 30 + 771 - 5, width: 500, height: 40 },
      fontSize: 22,
    }),

    north: makeSpec({
      rect: { x: 787, y: 30 + 750 + 12, width: 15, height: 15 },
      strokeWidth: 1,
    }),

    scaleline: makeSpec({
      rect: { x: 577, y: 30 + 745 + 10, width: 200, height: 27 },
      strokeWidth: 1,
      fontSize: 22,
    }),

    credits: makeSpec({
      rect: {
        x: 30 + 781 + 20,
        y: 841 - 30 - 40 + 5,
        width: 65,
        height: 40,
      },
      fontSize: 22,
      textAlign: "left",
    }),

    logo: makeSpec({
      rect: {
        x: 30 + 781 + 20 + 15 + 65,
        y: 841 - 30 - 40 + 10,
        width: 190,
        height: 12,
      },
    }),
  },
};

export default templates;
