/*
 *  Copyright (C) 2023 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import * as debug from 'debug';

import tr, { fromRecord } from '../locale';
import { DIV } from './elements';
import { makeIcon } from './button';
import { dispatch } from '../shape';
import { index } from 'fp-ts/lib/Array';
import { getAppMessage, getMaintenanceEvent } from '../app';
import { some } from 'fp-ts/lib/Option';

const logger = debug('sdi:app');

const closeBtn = makeIcon('close', 3, 'times', {
    text: () => tr.core('close'),
    position: 'left',
});

const removeEvent = (app: string) => {
    dispatch('app/maintenance', events => {
        const event = index(0, events);
        return event.foldL(
            () => events,
            () => []
        );
    });
    dispatch('app/messages', am => am.filter(m => m.app !== app));
};

export const maintenance = (app: string) => {
    // const element = document.createElement('div');
    // element.classList.add('maintenance');
    // getAppEvent(event, app).foldL(
    //     () =>
    //         element.append(
    //             document.createTextNode(fromRecord(event.announcement))
    //         ),
    //     app_event =>
    //         element.append(
    //             document.createTextNode(fromRecord(app_event.message))
    //         )
    // );

    // const close = document.createElement('div');
    // close.classList.add('close');
    // close.append(document.createTextNode(nameToString('times')));
    // close.addEventListener('click', () => document.body.removeChild(element));

    // element.append(close);
    // document.body.prepend(element);
    return getAppMessage(app).foldL(
        () =>
            getMaintenanceEvent().map(me =>
                DIV(
                    'app-announcement',
                    DIV('message', fromRecord(me.announcement)),
                    closeBtn(() => removeEvent(app))
                )
            ),
        appmessage =>
            some(
                DIV(
                    'app-announcement',
                    DIV('message', fromRecord(appmessage.message)),
                    closeBtn(() => removeEvent(app))
                )
            )
    );
};

export default maintenance;

logger('loaded');
