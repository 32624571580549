/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import { MessageRecordIO, nullable, TypeOf } from './io';
import * as io from 'io-ts';
import { fromNullable } from 'fp-ts/lib/Option';
import { catOptions } from 'fp-ts/lib/Array';
import { MessageRecord, MessageRecordLangIO } from './message';
import { IUserIO } from './user';

// tslint:disable-next-line: variable-name
export const AppPosterIO = io.interface(
    {
        icon: io.string,
        description: MessageRecordIO,
        image: io.string,
        categories: io.array(io.string),
        order: io.Integer,
    },
    'AppPosterIO'
);

type AppPoster = io.TypeOf<typeof AppPosterIO>;

const AppDisplayIO = io.interface({
    name: nullable(MessageRecordIO),
    option: nullable(io.union([io.literal('notInMenu'), io.literal('inMenu')])),
});
// type AppDisplay = io.TypeOf<typeof AppDisplayIO>;

// tslint:disable-next-line: variable-name
export const AppManifestIO = io.tuple(
    [
        io.string, // codename
        nullable(AppDisplayIO),
        io.string, // route name
        io.string, // url
        nullable(AppPosterIO), // poster
    ],
    'AppManifestIO'
);
export type AppManifest = io.TypeOf<typeof AppManifestIO>;

export const appName = (a: AppManifest) => a[0];
export const appDisplayName = (a: AppManifest) =>
    fromNullable(a[1]).chain(display => fromNullable(display.name));
export const appRoute = (a: AppManifest) => a[2];
export const appUrl = (a: AppManifest) => a[3];
export const appPoster = (a: AppManifest) => fromNullable(a[4]);
export const appDisplay = (a: AppManifest) =>
    fromNullable(a[1])
        .chain(display => fromNullable(display.option))
        .getOrElse('inMenu');

export type AppPosterManifest = AppPoster & {
    displayName: MessageRecord;
    url: string;
};

export const appPosters = (ms: AppManifest[]): AppPosterManifest[] =>
    catOptions(
        ms.map(m =>
            appPoster(m).chain(poster =>
                appDisplayName(m).map(displayName => ({
                    ...poster,
                    displayName,
                    url: appUrl(m),
                }))
            )
        )
    ).sort((a, b) => a.order - b.order);

// tslint:disable-next-line: variable-name
export const AppUrlIO = io.interface(
    {
        name: io.string,
        label: MessageRecordIO,
        url: MessageRecordIO,
    },
    'AppUrlIO'
);

export type AppUrl = io.TypeOf<typeof AppUrlIO>;

export const HighlightedBaseLayerIO = io.interface({
    id: io.number,
    sortIndex: io.number,
    name: MessageRecordIO,
    img: io.string,
    codename: io.string,
});
('HighlightedBaseLayerIO');

export type HighlightedBaseLayer = TypeOf<typeof HighlightedBaseLayerIO>;

export const AppMessageIO = io.interface({
    message: MessageRecordIO,
    app: io.string,
    start: io.number,
    end: io.number,
});
export type AppMessage = TypeOf<typeof AppMessageIO>;

export const MaintenanceEventIO = io.interface(
    {
        announcement: MessageRecordIO,
        start: io.number,
        end: io.number,
        // apps: io.array(AppMessageIO),
    },
    'MaintenanceEventIO'
);
export type MaintenanceEvent = TypeOf<typeof MaintenanceEventIO>;

const CRSIO = io.interface({ name: io.string, defs: io.string }, 'CRSIO');
const EPSG4326IO = io.literal('EPSG:4326', 'EPSG4326IO');
const EPSG3857IO = io.literal('EPSG:3857', 'EPSG3857IO');
const CRSConfigIO = io.union([EPSG4326IO, EPSG3857IO, CRSIO], 'ProjIO');

export type CRSConfig = io.TypeOf<typeof CRSConfigIO>;
export type EPSG4326IO = io.TypeOf<typeof EPSG4326IO>;
export type EPSG3857 = io.TypeOf<typeof EPSG3857IO>;

// tslint:disable-next-line: variable-name
export const AppConfigIO = io.interface(
    {
        user: nullable(IUserIO),
        highlighted_baselayers: io.array(HighlightedBaseLayerIO),
        languages: io.array(MessageRecordLangIO),
        args: io.array(io.string),
        api: io.string,
        csrf: io.string,
        root: io.string,
        apps: io.array(AppManifestIO),
        urls: io.array(AppUrlIO),
        admin_query: nullable(io.number),
        fonts: io.interface({
            picto: io.string,
            label: io.string,
        }),
        maintenance: io.array(MaintenanceEventIO),
        app_messages: io.array(AppMessageIO),
        dataCrs: nullable(CRSConfigIO),
        mapCrs: nullable(CRSConfigIO),
    },
    'AppConfigIO'
);
export type AppConfig = TypeOf<typeof AppConfigIO>;
