/*
 *  Copyright (C) 2022 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import { Coordinate } from 'ol/coordinate';

import { IMapBaseLayer } from 'sdi/source';

import { Foldable, Nullable } from 'sdi/util';
import { getFilter } from '../queries/mapnavigator';

export enum AppLayout {
    MapFS,
    MapAndInfo,
    MapAndFeature,
    MapAndExtract,
    MapNavigatorFS,
    MapAndTracker,
    MapAndMeasure,
    Print,
    Query,
    QueryPrint,
}

export enum SortDirection {
    ascending,
    descending,
}

export type TableDataKey = string;

export type IMenuData = Foldable;

export interface IToolWebServices extends Foldable {
    url: string;
    layers: IMapBaseLayer[];
}

export type LegendPage =
    | 'base-map'
    | 'data'
    | 'info'
    | 'locate'
    | 'locate-bookmark'
    | 'measure'
    | 'print'
    | 'share'
    | 'spatial-filter';

export interface ILegend {
    currentPage: LegendPage;
}

export interface TrackerCoordinate {
    coord: Coordinate;
    accuracy: number;
}

export interface IGeoTracker {
    track: TrackerCoordinate[];
    active: boolean;
}

export interface IPositioner {
    point: {
        latitude: number;
        longitude: number;
    };
}

export interface IGeoMeasure {
    active: boolean;
    geometryType: 'Polygon' | 'LineString';
    coordinates: Coordinate[];
}

export interface IShare {
    withView: boolean;
}

export const filterCat = (categories: string[]): HomeFilter => ({
    ...getFilter(),
    categories,
});

export const filterPat = (pattern: string): HomeFilter => ({
    ...getFilter(),
    pattern,
});

export const defaultFilter = (): HomeFilter => ({
    categories: [],
    pattern: null,
});

export type HomeFilter = {
    pattern: Nullable<string>;
    categories: string[];
};

export type HomeConfigTile = {
    mode: 'tile';
    filter: HomeFilter;
};

export type HomeTableKey =
    | 'colTitle'
    | 'colDescription'
    | 'colCategory'
    | 'colDate'
    | 'colAuthor'
    | 'colStatus';

export type HomeTableSort = [HomeTableKey, 'asc' | 'desc'];

export type HomeConfigTable = {
    mode: 'table';
    filter: HomeFilter;
    sort: HomeTableSort;
};
export type HomeConfig = HomeConfigTile | HomeConfigTable;

export type HomeMode = HomeConfig['mode'];

export const defaultHomeConfigTile = (
    filter = defaultFilter()
): HomeConfigTile => ({
    mode: 'tile',
    filter,
});

export const defaultHomeConfigTable = (
    filter = defaultFilter()
): HomeConfigTable => ({
    mode: 'table',
    sort: ['colTitle', 'asc'],
    filter,
});
