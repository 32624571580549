/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import { Coordinate } from 'ol/coordinate';
import { fromNullable, none, Option } from 'fp-ts/lib/Option';

import { iife } from 'sdi/lib';
import { fromRecord } from 'sdi/locale';
import { scopeOption } from 'sdi/lib/scope';
import { dispatch, dispatchK } from 'sdi/shape';
import {
    viewEventsFactory,
    scaleEventsFactory,
    trackerEventsFactory,
    measureEventsFactory,
    ExtractFeature,
    defaultInteraction,
    PrintRequest,
    PrintResponse,
    addLayer,
    FeaturePath,
} from 'sdi/map';
import clientLayer from 'sdi/map/client-layer';
import { tableEvents } from 'sdi/components/table';
import { Feature, MessageRecord, StyleConfig } from 'sdi/source';
import { nameToCode } from 'sdi/components/button/names';

import { getInteraction, HarvestLayerName, withExtract } from '../queries/map';
import { PrintProps } from '../components/print';
import { mapName } from '../components/map';
import { getGeometry } from '../queries/harvest';
import { AppLayout } from '../shape/types';
import { setPage } from '../events/legend';
import { clearSelectedRow } from '../events/table';
import { navigateMapFeature } from '../events/route';
import { getCurrentMap, getSelectedFeatures } from '../queries/app';
import {
    clearSelectedFeatures,
    pushFeaturePathToSelection,
    setCurrentFeatureById,
    setLayout,
    updateCurrentMapInPlace,
} from './app';
import { updatePositionerLongitude, updatePositionerLatitude } from './legend';

export const setInteraction = dispatchK('port/map/interaction');

export const resetInteraction = () => setInteraction(defaultInteraction);

export const scalelineEvents = scaleEventsFactory(dispatchK('port/map/scale'));
export const dispatchView = dispatchK('port/map/view');
export const viewEvents = viewEventsFactory(dispatchView);
export const trackerEvents = trackerEventsFactory(
    setInteraction,
    getInteraction
);
export const measureEvents = measureEventsFactory(
    setInteraction,
    getInteraction
);

export const startExtract = () =>
    setInteraction(() => ({
        label: 'extract',
        state: [],
    }));

export const stopExtract = () => setInteraction(defaultInteraction);

const eq = (a: ExtractFeature[], b: ExtractFeature[]) =>
    a.length === b.length &&
    a.length ===
        a.filter((e, i) => b[i] && e.featureId === b[i].featureId).length;

const withNewExtracted = (a: ExtractFeature[]) =>
    withExtract().map(b => eq(a, b.state));

export const setExtractCollection = (es: ExtractFeature[]) =>
    withNewExtracted(es).fold(
        setInteraction(() => ({
            label: 'extract',
            state: es,
        })),
        isEq => {
            if (!isEq) {
                setInteraction(() => ({
                    label: 'extract',
                    state: es,
                }));
            }
        }
    );

export const extractTableEvents = tableEvents(
    dispatchK('component/table/extract')
);

export const startMark = () =>
    setInteraction(s => {
        if (s.label === 'mark') {
            return { ...s, state: { ...s.state, started: true } };
        }
        return s;
    });

export const endMark = () => setInteraction(() => defaultInteraction());

export const putMark = (coordinates: Coordinate) =>
    setInteraction(() => ({
        label: 'mark',
        state: {
            started: false,
            endTime: Date.now() + 12000,
            coordinates,
        },
    }));

export const startPointerPosition = (after: (c: Coordinate) => void) =>
    setInteraction(() => ({
        label: 'position',
        state: { coordinates: [0, 0], after },
    }));

export const setPointerPosition = (coordinates: Coordinate) =>
    setInteraction(it => {
        if ('position' === it.label) {
            return {
                ...it,
                state: { ...it.state, coordinates },
            };
        }
        return it;
    });

export const stopPointerPosition = (coordinate: Option<Coordinate>) => {
    const it = getInteraction();
    if (it.label === 'position') {
        coordinate.map(c => {
            const after = it.state.after;
            const coordinates = it.state.coordinates;
            updatePositionerLongitude(Math.floor(c[0]));
            updatePositionerLatitude(Math.floor(c[1]));
            setTimeout(() => after(coordinates), 1);
        });
        setInteraction(() => defaultInteraction());
    }
};

export const updateLoading = (ms: MessageRecord[]) =>
    dispatch('port/map/loading', () => ms);

export const updateLoadingAddOne = (m: MessageRecord) =>
    dispatch('port/map/loading', ms =>
        ms.filter(mm => fromRecord(mm) !== fromRecord(m)).concat(m)
    );

export const updateLoadingRemoveOne = (m: MessageRecord) =>
    dispatch('port/map/loading', ms =>
        ms.filter(mm => fromRecord(mm) !== fromRecord(m))
    );

export const setPrintRequest = (r: PrintRequest<PrintProps>) => {
    dispatchK('port/map/printRequest')(() => r);
    dispatchK('port/map/interaction')(() => ({
        label: 'print',
        state: null,
    }));
};
export const setPrintResponse = (r: PrintResponse<PrintProps>) =>
    dispatchK('port/map/printResponse')(() => r);

export const stopPrint = () => setInteraction(defaultInteraction);

export const setZoom = (zoom: number) =>
    viewEvents.updateMapView({
        dirty: 'geo',
        zoom,
    });

// export const zoomToFeature = (feature: Feature) =>
//     viewEvents.updateMapView({
//         dirty: 'geo/feature',
//         feature,
//     });
export const zoomToFeatures = (features: Feature[]) =>
    viewEvents.updateMapView({
        dirty: 'geo/feature',
        features,
    });

export const harvestLayerInfo = () =>
    getGeometry().map(geometry => {
        const style = iife((): StyleConfig => {
            switch (geometry.type) {
                case 'Point':
                case 'MultiPoint':
                    return {
                        kind: 'point-simple',
                        marker: {
                            codePoint: nameToCode('info-circle'),
                            color: 'black',
                            size: 22,
                        },
                    };
                case 'LineString':
                case 'MultiLineString':
                    return {
                        kind: 'line-simple',
                        dash: [],
                        strokeColor: 'black',
                        strokeWidth: 3,
                    };
                case 'Polygon':
                case 'MultiPolygon':
                    return {
                        kind: 'polygon-simple',
                        fillColor: 'transparent',
                        strokeColor: 'black',
                        strokeWidth: 3,
                    };
            }
        });

        const { data, info, layer } = clientLayer(
            HarvestLayerName,
            geometry.type,
            style,
            [{ type: 'Feature', id: 0, properties: {}, geometry }]
        );
        return { style, data, info, layer };
    });

export const harvestMetadata = () =>
    harvestLayerInfo().chain(({ info }) => info().map(i => i.metadata));
export const addHarvestLayer = () =>
    harvestLayerInfo().map(({ info, data, layer }) => {
        updateCurrentMapInPlace(map => map.layers.push(layer));
        addLayer(mapName, info, data);
    });

export const removeHarvestLayer = () => {
    // removeLayer(mapName, HarvestLayerName); // done automatically when map is synchronizing
    updateCurrentMapInPlace(map => {
        map.layers = map.layers.filter(layer => layer.id !== HarvestLayerName);
    });
};

export const startTracker = () => {
    setLayout(AppLayout.MapAndTracker);
    trackerEvents.startTrack();
};
export const clearSelection = () => {
    clearSelectedRow();
    clearSelectedFeatures();
    setLayout(AppLayout.MapAndInfo);
    setPage('info');
};

export const selectFeature = (lid: string, fid: string | number) => {
    clearSelectedRow();
    setCurrentFeatureById(lid, fid);
    fromNullable(getCurrentMap()).map(mid => navigateMapFeature(mid, lid, fid));
};

export const selectFeatures = (paths: FeaturePath[]) => {
    clearSelectedFeatures();
    paths.map(p => {
        scopeOption()
            .let('lid', fromNullable(p.layerId))
            .let('fid', fromNullable(p.featureId))
            .map(({ lid, fid }) => pushFeaturePathToSelection(lid, fid));
    });
    if (getSelectedFeatures() !== none) {
        setLayout(AppLayout.MapAndFeature);
    } else {
        setLayout(AppLayout.MapAndInfo);
    }
    // const currentLayout = getLayout();
    // if (
    //     currentLayout !== AppLayout.MapAndFeature &&
    //     currentLayout !== AppLayout.MapAndTableAndFeature
    // ) {
    //     if (currentLayout === AppLayout.MapAndTable) {
    //         setLayout(AppLayout.MapAndTableAndFeature);
    //     } else {
    //         setLayout(AppLayout.MapAndFeature);
    //     }
    // } else if (noSelectedFeatures()) {
    //     if (currentLayout === AppLayout.MapAndFeature) {
    //         setLayout(AppLayout.MapAndInfo);
    //     } else {
    //         setLayout(AppLayout.MapAndTable);
    //     }
    // }
};

// export const getSelected = () =>
//     getCurrentFeature().fold<FeaturePath>(
//         { featureId: null, layerId: null },
//         f => ({ featureId: f.id, layerId: getCurrentLayer() })
//     );
