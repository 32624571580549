import * as debug from 'debug';
import Map from 'ol/Map';
import Collection from 'ol/Collection';
import { Extent } from 'ol/extent';
import { Size } from 'ol/size';
import SourceTileWMS from 'ol/source/TileWMS';

import { fromNullable } from 'fp-ts/lib/Option';

import {
    PrintRequest,
    PrintResponse,
    PrintOptions,
    withInteraction,
    InteractionPrint,
} from '..';
import { scopeOption } from '../../lib/scope';
import { TileSourceEvent } from 'ol/source/Tile';
import { TileLayer, VectorLayer } from '../map';
import { tryTuple2, tryTuple4 } from '../../util/index';
import { index } from 'fp-ts/lib/Array';
import { IMapInfo } from '../../source/io/map';

const logger = debug('sdi:map/print');

export const print = <T>({ getRequest, setResponse }: PrintOptions<T>) => {
    const pendings: PrintResponse<T>[] = [];
    let mapRef: Map | undefined;
    let baseLayersRef: Collection<TileLayer>;
    let mainLayerGroup: Collection<VectorLayer>;
    let getMapInfo: () => IMapInfo | null = () => null;

    let originalSize: Size | undefined;
    let originalExtent: Extent | undefined;

    const getResponseFromPendings = (id: string) =>
        fromNullable(pendings.find(r => r.id === id));

    const updateResponse =
        (req: PrintRequest<T>) =>
        (id: string, u: Partial<PrintResponse<T>>) => {
            const idx = pendings.findIndex(r => r.id === id);
            if (idx >= 0) {
                const r = pendings[idx];
                pendings[idx] = {
                    ...r,
                    ...u,
                    props: req.props,
                };
                setResponse(pendings[idx]);
            }
        };

    let layerState: [
        id: string,
        visibility: boolean,
        minZoom: number,
        maxZoom: number
    ][] = [];

    const adpatVisibilityForPrint = () =>
        scopeOption()
            .let('map', fromNullable(mapRef))
            .let('layers', fromNullable(mainLayerGroup))
            .let('mapInfo', fromNullable(getMapInfo()))
            .map(({ map, layers, mapInfo }) => {
                layerState = [];
                const zoom = map.getView().getZoom() ?? 0;

                mapInfo.layers.forEach(info => {
                    const min = info.minZoom ?? 0;
                    const max = info.maxZoom ?? 30;
                    const visible = max > zoom && min <= zoom;
                    fromNullable(
                        layers.getArray().find(l => l.get('id') === info.id)
                    ).map(l => {
                        layerState.push([
                            info.id,
                            l.getVisible(),
                            l.getMinZoom(),
                            l.getMaxZoom(),
                        ]);
                        l.setVisible(visible);
                        l.setMinZoom(0);
                        l.setMaxZoom(30);
                    });
                });
            });

    const restoreLayers = (layers: Collection<VectorLayer>) =>
        layerState.forEach(([id, visibility, minZoom, maxZoom]) => {
            fromNullable(layers.getArray().find(l => l.get('id') === id)).map(
                l => {
                    l.setVisible(visibility);
                    l.setMinZoom(minZoom);
                    l.setMaxZoom(maxZoom);
                }
            );
        });

    const restoreMap = () =>
        scopeOption()
            .let('map', fromNullable(mapRef))
            .let('size', fromNullable(originalSize))
            .let('extent', fromNullable(originalExtent))
            .let('layers', fromNullable(mainLayerGroup))
            .map(({ map, size, extent, layers }) => {
                restoreLayers(layers);
                const target = map.getTargetElement();
                target.style.width = ''; // `${width}px`;
                target.style.height = ''; // `${height}px`;
                map.setSize(size);
                map.getView().fit(extent, { size });
            });

    const startPrint = (req: PrintRequest<T>) => {
        const reqId = req.id;
        if (reqId === null) {
            return;
        }

        originalSize = mapRef?.getSize();
        originalExtent = mapRef?.getView().calculateExtent(originalSize);
        logger('zoom RESOLUTON A', mapRef?.getView().getResolution());

        const noneResponse: PrintResponse<T> = {
            id: req.id,
            data: '',
            extent: [0, 0, 0, 0],
            status: 'none',
            props: req.props,
        };
        pendings.push(noneResponse);
        setResponse(noneResponse);

        const updateResponseWithReq = updateResponse(req);

        scopeOption()
            .let('map', fromNullable(mapRef))

            .let('extent', fromNullable(originalExtent))
            .let('baseLayers', fromNullable(baseLayersRef))
            .map(({ map, baseLayers, extent }) => {
                adpatVisibilityForPrint();

                const afterResize = () => {
                    // needed to send to scaleline
                    const resizedExtent = map
                        .getView()
                        .calculateExtent(map.getSize());
                    logger(
                        `zoomLevel: ${map.getView().getZoom()}`,
                        map.getView().getResolution()
                    );
                    const targetCanvas = document.createElement('canvas');
                    fromNullable(map.getSize())
                        .chain(tryTuple2)
                        .map(([width, height]) => {
                            targetCanvas.width = width;
                            targetCanvas.height = height;
                        });
                    logger(
                        `targetCanvas: w:${targetCanvas.width} h:${targetCanvas.height}`
                    );

                    map.once('postrender', () => {
                        logger(`postrender ${map.getSize()}`);
                    });

                    map.once('rendercomplete', () => {
                        logger(`rendercomplete ${map.getSize()}`);
                        fromNullable(targetCanvas.getContext('2d')).map(
                            targetContext => {
                                const viewport = map.getViewport();
                                const canvasList =
                                    viewport.querySelectorAll('canvas');
                                Array.from(canvasList).forEach(canvas => {
                                    // adapted from https://openlayers.org/en/latest/examples/export-pdf.html
                                    if (canvas.width > 0) {
                                        fromNullable(
                                            canvas.parentNode as HTMLElement | null
                                        ).map(n => {
                                            const opacity = n.style.opacity;
                                            targetContext.globalAlpha =
                                                opacity === ''
                                                    ? 1
                                                    : Number(opacity);
                                        });
                                        fromNullable(canvas.style.transform)
                                            .chain(transform =>
                                                fromNullable(
                                                    transform.match(
                                                        /^matrix\(([^(]*)\)$/
                                                    )
                                                )
                                            )
                                            .chain(match => index(1, match))
                                            .map(values => {
                                                // Get the transform parameters from the style's transform matrix
                                                const matrix = values
                                                    .split(',')
                                                    .map(Number);
                                                // Apply the transform to the export map context
                                                CanvasRenderingContext2D.prototype.setTransform.apply(
                                                    targetContext,
                                                    matrix
                                                );
                                            });
                                        targetContext.drawImage(canvas, 0, 0);
                                    }
                                });
                            }
                        );
                        const data = targetCanvas.toDataURL('image/png');
                        tryTuple4(resizedExtent).map(extent =>
                            updateResponseWithReq(reqId, {
                                data,
                                extent,
                                status: 'end',
                            })
                        );
                        restoreMap();
                    });

                    map.once('postcompose', () =>
                        fromNullable(baseLayers.item(0))
                            .map(base => {
                                // logger(
                                //     `postcompose ${targetCanvas.width} ${targetCanvas.height}`
                                // );

                                const baseSource =
                                    base.getSource() as unknown as SourceTileWMS;
                                let loaded = 0;
                                let loading = 0;

                                const loadStart = () => {
                                    loading += 1;
                                    if (loading === 1) {
                                        updateResponseWithReq(reqId, {
                                            status: 'start',
                                        });
                                    }
                                };

                                const loadError = () => {
                                    updateResponseWithReq(reqId, {
                                        status: 'error',
                                    });
                                    clearHandlers();
                                    restoreMap();
                                };

                                const loadEnd = (_e: TileSourceEvent) => {
                                    loaded += 1;
                                    if (loading > loaded) {
                                        return;
                                    }
                                    clearHandlers();
                                };

                                const clearHandlers = () => {
                                    baseSource.un('tileloadstart', loadStart);
                                    baseSource.un('tileloaderror', loadError);
                                    baseSource.un('tileloadend', loadEnd);
                                };

                                baseSource.on('tileloadstart', loadStart);
                                baseSource.on('tileloaderror', loadError);
                                baseSource.on('tileloadend', loadEnd);

                                baseSource.refresh();
                            })
                            .getOrElseL(() =>
                                updateResponseWithReq(reqId, {
                                    status: 'start',
                                })
                            )
                    );

                    map.renderSync();
                };

                const width = Math.round((req.width * req.resolution) / 25.4);
                const height = Math.round((req.height * req.resolution) / 25.4);
                // target.style.width = `${width}px`;
                // target.style.height = `${height}px`;
                map.setSize([width, height]);
                // canvas.width = width;
                // canvas.height = height;
                // map.updateSize()

                // map.on('change:size', (e) => {
                //     const [w, h] = map.getSize()!
                //     if (w !== width || h !== height) {
                //         // throw new Error('Wrong Size')
                //         logger('change:size', e.oldValue, map.getSize())
                //     }
                // })
                logger(`RESIZE ${width} ${height}`);
                map.getView().fit(extent, {
                    size: [width, height],
                    callback: afterResize,
                });
            })
            .foldL(
                () => logger(`Failed to start print`),
                () => logger(`Print Started`)
            );
    };

    const update = withInteraction<InteractionPrint>(
        'print',
        () => {
            const req = getRequest();
            const reqId = req.id;
            if (reqId) {
                getResponseFromPendings(reqId).foldL(
                    () => startPrint(req),
                    () => null
                );
            }
        },
        () => null
    );

    const init = (
        map: Map,
        baseLayers: Collection<TileLayer>,
        gmi: () => IMapInfo | null,
        mlg: Collection<VectorLayer>
    ) => {
        mapRef = map;
        baseLayersRef = baseLayers;
        getMapInfo = gmi;
        mainLayerGroup = mlg;
    };

    return { init, update };
};

logger('loaded');
