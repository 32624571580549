/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
import { none } from 'fp-ts/lib/Option';
import * as debug from 'debug';

import { observe, dispatchK, dispatch, queryK } from 'sdi/shape';
import { TableSelection } from 'sdi/components/table2';
import { Feature } from 'sdi/source';
import {
    getSyntheticLayerInfoOption,
    getCurrentLayerInfo,
    getLayerData,
} from '../queries/app';
import {
    clearSelectedFeatures,
    pushFeaturePathToSelection,
    setLayout,
} from './app';
import { makeTable2Manip } from 'sdi/components/table2/layer';
import { AppLayout } from '../shape/types';
import { getGeometry } from '../queries/harvest';

const logger = debug('sdi:events/table');

export const tableDispatch = dispatchK('component/table');
const dispatchStreaming = dispatchK('data/features/stream');

export const updateLayer = (uri: string, features: Feature[]) =>
    dispatch('data/layers', layers => {
        // setLayout(AppLayout.MapAndFeature);
        const layer = layers[uri];
        if (layer !== undefined) {
            features.forEach(feature => {
                if (layer.features.findIndex(f => f.id === feature.id) < 0) {
                    layer.features.push(feature);
                }
            });
        }
        return layers;
    });

const selectRow = (r: TableSelection) =>
    dispatch('component/table', state => ({
        ...state,
        selected: r,
    }));

export const clearSelectedRow = () =>
    dispatch('component/table', state => ({
        ...state,
        selected: [],
    }));

export const selectFeatureFromRow = (rows: TableSelection) => {
    clearSelectedFeatures();
    getCurrentLayerInfo().map(({ info }) => {
        selectRow(rows);
        rows.map(row => pushFeaturePathToSelection(info.id, row.from));
        setLayout(AppLayout.MapAndFeature);
    });
};

export const { layerObserver, tableObserver, clearCurrentStreamData } =
    makeTable2Manip(
        getCurrentLayerInfo,
        getSyntheticLayerInfoOption,
        getLayerData,
        getGeometry,
        queryK('component/table'),
        queryK('data/features/stream'),
        () => none,
        dispatchStreaming,
        updateLayer,
        true
    );

observe('app/current-layer', layerObserver);
observe('component/table', tableObserver);

logger('loaded');
