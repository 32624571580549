/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

/**
 * Messages are ICU formated messages, see guide at
 * http://userguide.icu-project.org/formatparse/messages
 */
export const messages = {
    __empty__: {
        fr: '',
        nl: '',
        en: '',
    },

    comma: {
        en: ', ',
        nl: ', ',
        fr: ', ',
    },

    dot: {
        en: '. ',
        nl: '. ',
        fr: '. ',
    },

    space: {
        en: ' ',
        nl: ' ',
        fr: ' ',
    },

    add: {
        fr: 'Ajouter',
        nl: 'Toevoegen', // nldone
        en: 'Add',
    },

    address: {
        fr: 'Adresse',
        nl: 'Adres', // nldone
        en: 'Adress',
    },
    at: {
        fr: 'à',
        nl: 'op',
        en: 'at',
    },

    contact: {
        fr: 'Contact',
        nl: 'Contact', // nldone
        en: 'Contact',
    },

    adjustColumnSize: {
        fr: 'Ajuster la largeur de la colonne',
        nl: 'Aanpassen van de kolombreedte', // nltocheck
        en: 'Adjusting the column width',
    },
    increaseColumnSize: {
        fr: 'Augmenter la largeur de la colonne',
        nl: 'Kolombreedte vergroten', // nltocheck
        en: 'Increase the width of the column',
    },
    reduceColumnSize: {
        fr: 'Diminuer la largeur de la colonne',
        nl: 'Kolombreedte verminderen', // nltocheck
        en: 'Reduce the width of the column',
    },
    increase: {
        fr: 'Augmenter',
        nl: 'Verhogen', //nltocheck,
        en: 'Increase',
    },
    decrease: {
        fr: 'Diminuer',
        nl: 'Verkleinen', //nltocheck
        en: 'Decrease',
    },

    applications: {
        fr: 'Applications',
        nl: 'Applicaties', // nldone
        en: 'Applications',
    },

    attachedFiles: {
        fr: 'Documents',
        nl: 'Documenten', // nldone
        en: 'Documents',
    },

    attachmentUpload: {
        fr: 'Upload',
        nl: 'Uploaden', // nldone
        en: 'Upload',
    },

    attachmentUploadActive: {
        fr: 'Uploading',
        nl: 'Bezig met uploaden', // nldone
        en: 'Uploading',
    },

    browseMaps: {
        fr: 'Feuilleter des cartes',
        nl: 'Bekijk de kaarten', // nldone
        en: 'Browse maps',
    },

    cancel: {
        fr: 'Annuler',
        nl: 'Annuleren', // nldone
        en: 'Cancel',
    },

    charts: {
        fr: 'Graphiques',
        nl: 'Grafieken', // nldone
        en: 'Charts',
    },

    clear: {
        fr: 'Supprimer',
        nl: 'Verwijderen', // nldone
        en: 'Delete',
    },

    close: {
        fr: 'Fermer',
        nl: 'Sluiten', // nldone
        en: 'Close',
    },

    collapse: {
        fr: 'Replier',
        nl: 'Sluiten',
        en: 'Collapse',
    },

    colorHue: {
        fr: 'Teinte',
        nl: 'Tint', // nldone
        en: 'Hue',
    },
    colorOpacity: {
        fr: 'Opacité',
        nl: 'Opaciteit', // nldone
        en: 'Opacity',
    },
    colorSaturation: {
        fr: 'Saturation',
        nl: 'Saturatie', // nldone
        en: 'Saturation',
    },
    colorLightness: {
        fr: 'Luminosité',
        nl: 'Helderheid', // nldone
        en: 'Lightness',
    },

    connect: {
        fr: 'Connecter',
        nl: 'Connecteren', // nldone
        en: 'Connect',
    },

    datas: {
        fr: 'Données',
        nl: 'Data', // nldone
        en: 'Datas',
    },

    editFeatureTemplate: {
        fr: 'Éditer la fiche individuelle',
        nl: 'Bewerk de individuele fiche', // nldone
        en: 'Edit feature view',
    },

    readMore: {
        fr: 'Plus',
        nl: 'Meer',
        en: 'Read more',
    },
    readLess: {
        fr: 'Moins',
        nl: 'Minder',
        en: 'Read less',
    },

    expand: {
        fr: 'Déplier',
        nl: 'Openen',
        en: 'Expand',
    },

    featureTemplateEditorReset: {
        fr: 'Réinitialiser la fiche individuelle',
        nl: 'Reset individuele fiche', // nldone
        en: 'Reset feature view',
    },

    featureViewTypeDefault: {
        fr: 'Standard',
        nl: 'Standaard', // nldone
        en: 'Default',
    },

    featureViewTypeTemplate: {
        fr: 'Template',
        nl: 'Template', // nldone
        en: 'Template',
    },

    featureViewTypeConfig: {
        fr: 'Configurable',
        nl: 'Configuratie', // nldone
        en: 'Configuration',
    },

    featureViewTypeTimeserie: {
        fr: 'Bar chart',
        nl: 'Staafdiagram', // nldone
        en: 'Bar chart',
    },

    featureViewTypePieChart: {
        fr: 'Diagramme circulaire',
        nl: 'Taartdiagram', // nldone
        en: 'Pie chart',
    },

    featureViewUnvalidTemplate: {
        fr: 'Template invalide',
        nl: 'Ongeldige template', // nldone
        en: 'Invalid template',
    },

    editLayer: {
        fr: 'Éditer',
        nl: 'Bewerken', // nldone
        en: 'Edit',
    },

    emptyDescription: {
        fr: 'Description',
        nl: 'Beschrijving', // nldone
        en: 'Description',
    },

    emptyTitle: {
        fr: 'Titre',
        nl: 'Titel', // nldone
        en: 'Title',
    },

    export: {
        fr: 'Export',
        nl: 'Exporteren', // nldone
        en: 'Export',
    },

    exportCSV: {
        fr: 'Export .csv',
        nl: '.csv Exporteren', // to check
        en: 'Export .csv',
    },
    exportXLSX: {
        fr: 'Export .xlsx',
        nl: '.xlsx Exporteren', // to check
        en: 'Export .xlsx',
    },

    featureInfos: {
        fr: 'Informations sur l’élément',
        nl: 'Informatie over het item', // nldone
        en: 'Feature informations',
    },
    info: {
        fr: 'Informations',
        nl: 'Informatie', // nldone
        en: 'Informations',
    },

    go: {
        fr: 'Aller',
        nl: 'Gaan', // nldone
        en: 'Go',
    },

    geocode: {
        fr: 'Chercher une adresse',
        nl: 'Zoek een adres', // nldone
        en: 'Search an address',
    },

    geocodeNoResults: {
        fr: "Pas d'adresse trouvée",
        nl: 'Geen adres gevonden',
        en: 'No address found',
    },

    login: {
        fr: 'Se connecter',
        nl: 'Inloggen', // nldone
        en: 'Sign in',
    },

    logout: {
        fr: 'Se déconnecter',
        nl: 'Uitloggen', // nldone
        en: 'Logout',
    },

    mapTools: {
        fr: 'Outils',
        nl: 'Tools', // nldone
        en: 'Tools',
    },

    mapRefList: {
        fr: 'Cartes',
        nl: 'Kaarten', // nldone
        en: 'Maps',
    },

    rowNumberColTitle: {
        fr: '#',
        nl: '#',
        en: '#',
    },

    save: {
        fr: 'Sauvegarder',
        nl: 'Bewaren', // nldone
        en: 'Save',
    },

    search: {
        fr: 'Recherche',
        nl: 'Zoeken', // nldone
        en: 'Search',
    },

    searchCoord: {
        fr: 'Coordonnées X Y en Lambert Belge 72, séparées par un espace. E.g., 148850 170700',
        nl: 'X Y coördinaten in Belgian Lambert 72, gescheiden door een spatie. E.g., 148850 170700',
        en: 'X Y coordinates in Belgian Lambert 72, separated by a space. E.g., 148850 170700',
    },

    start: {
        fr: 'Démarrer',
        nl: 'Starten', // nldone
        en: 'Start',
    },

    stop: {
        fr: 'Arrêter',
        nl: 'Stop', // nldone
        en: 'Stop',
    },

    operator: {
        fr: 'opérateur',
        nl: 'operator', // nltocheck
        en: 'operator',
    },

    timeserieFeatureProperty: {
        fr: 'Attribute',
        nl: 'Kenmerk', // nldone
        en: 'Attributes',
    },

    timeserieFeaturePropertyNone: {
        fr: 'N’utilise pas d’attribut',
        nl: 'Gebruik geen kenmerk', // nldone
        en: 'Doesn’t use attribute',
    },

    timeserieURL: {
        fr: 'url',
        nl: 'url', // nldone
        en: 'url',
    },

    timeserieConfigError: {
        fr: 'No or malformed configuration for this timeserie',
        nl: 'Fout bij het laden van de configuratie van deze grafiek', // grafiek ou 'staafdiagram' si c'est plus specifique
        en: 'No or malformed configuration for this timeserie',
    },

    legend: {
        fr: 'Légende',
        nl: 'Legende', // nldone
        en: 'Legend',
    },

    legendType: {
        fr: 'Type de légende',
        nl: 'Type legende', // nldone
        en: 'Legend type',
    },

    mapInfoAddIllustration: {
        fr: 'Sélectionnez une image',
        nl: 'Selecteer een afbeelding', // nldone
        en: 'Select an image',
    },

    mapInfoChangeIllustration: {
        fr: 'Sélectionnez une autre image',
        nl: 'Selecteer een andere afbeelding', // nldone
        en: 'Select another image',
    },

    remove: {
        fr: 'Supprimer',
        nl: 'Verwijderen', // nldone
        en: 'Remove',
    },

    share: {
        fr: 'Partager',
        nl: 'Delen', // nldone
        en: 'Share',
    },

    sort: {
        fr: 'Trier',
        nl: 'Sorteren', // nltocheck
        en: 'Sort',
    },

    switchLang: {
        fr: 'NL',
        nl: 'FR',
        en: 'EN',
    },

    styleGroupSelectedItemsCount: {
        fr: 'Éléments séléctionné',
        nl: 'Geselecteerde items', // nldone
        en: 'Selected item',
    },

    styleGroupRemove: {
        fr: 'Supprimer la catégorie',
        nl: 'Verwijder categorie', // nldone
        en: 'Delete category',
    },

    validate: {
        fr: 'Valider',
        nl: 'Bevestigen', // nldone
        en: 'Validate',
    },

    webServiceUrl: {
        fr: 'URL d’un webservice',
        nl: 'URL van een webservice', // nldone
        en: 'Web service URL',
    },

    loadingData: {
        fr: 'Chargement des données',
        nl: 'Data worden geladen', // nldone
        en: 'Loading datas',
    },

    valuesCovered: {
        fr: 'match',
        nl: 'match', // nldone
        en: 'match',
    },

    map: {
        fr: '{n, plural, =0 {Carte} =1 {Carte} other {Cartes}}',
        nl: '{n, plural, =0 {Kaart} =1 {Kaart} other {Kaarten}}', // nldone
        en: '{n, plural, =0 {Map} =1 {Map} other {Maps}}',
        parameters: { n: 1 },
    },

    layer: {
        fr: '{n, plural, =0 {Couche} =1 {Couche} other {Couches}}',
        nl: '{n, plural, =0 {Laag} =1 {Laag} other {Lagen}}', // nldone
        en: '{n, plural, =0 {Layer} =1 {Layer} other {Layers}}',
        parameters: { n: 1 },
    },

    link: {
        fr: 'Lien',
        nl: 'Link', // nldone
        en: 'Link',
    },

    confirm: {
        fr: 'Confirmer',
        nl: 'Bevestigen', // nldone
        en: 'Confirm',
    },

    confirmDelete: {
        fr: 'Confirmation',
        nl: 'Bevestiging', // nldone
        en: 'Confirmation',
    },

    propName: {
        fr: 'Nom d’attribut',
        nl: 'Naam van het attribuut', // nldone
        en: 'Attribute name',
    },

    newLayer: {
        fr: 'Nouvelle couche',
        nl: 'Nieuwe laag', // nldone
        en: 'New layer',
    },

    responsiblePerson: {
        fr: 'Personne responsable',
        nl: 'Verantwoordelijke', // nldone
        en: 'Responsible individual',
    },

    codePoint: {
        fr: 'Code de caractère',
        nl: 'Tekencode', // nldone
        en: 'Character code',
    },

    legendItemAdd: {
        fr: 'Ajouter un élément de légende',
        nl: 'Voeg een item aan de legende toe', // nldone
        en: 'Add a legend item',
    },

    templateEditorExplanation: {
        fr: 'Template. The template will be updated on blur. Variable: <%= attribute_name %>. Or between <% %> plain javascript.',
        nl: 'Template. De template zal wazig geüpdated worden. Variabele: <%= attribute_name %>. Of tussen <% %> plain javascript.',
        en: 'Template. The template will be updated on blur. Variable: <%= attribute_name %>. Or between <% %> plain javascript.',
    }, // nldone

    dashboard: {
        fr: 'Applications',
        nl: 'Applicaties', // nldone
        en: 'Applications',
    },

    noResults: {
        fr: 'Pas de résultat',
        nl: 'Geen resultaten', // nldone
        en: 'No results',
    },

    viewLayer: {
        fr: 'Visualiser la couche',
        nl: 'Toon de laag', // nldone
        en: 'View layer',
    },
    hideLayer: {
        fr: 'Cacher la couche',
        nl: 'Verberg de laag', // nl todo
        en: 'Hide layer',
    },

    label: {
        fr: 'Label',
        nl: 'Label', // nldone
        en: 'Label',
    },

    columnNumber: {
        fr: 'N° de colonne',
        nl: 'Kolomnummer', // nldone
        en: 'Column number',
    },

    metadataEditor: {
        fr: 'Édition des métadonnées',
        nl: 'Bewerking van de metadata', // nldone
        en: 'Metadatas editor',
    },

    layerInfo: {
        fr: 'Informations',
        nl: 'Informatie', // nldone
        en: 'Informations',
    },

    responsibleOrganisation: {
        fr: 'Organisation responsable',
        nl: 'Verantwoordelijke organisatie', // nldone
        en: 'Responsible organisation',
    },

    saving: {
        fr: 'Mise à jour des données',
        nl: 'Gegevens bijwerken', // nldone
        en: 'Updating datas',
    },

    identifier: {
        fr: 'Identifiant',
        nl: 'Identificatie', // nldone
        en: 'Identifier',
    },

    keywords: {
        fr: 'Mots clés',
        nl: 'Trefwoorden', // nldone
        en: 'Keyword',
    },

    topics: {
        fr: 'Thème',
        nl: 'Onderwerp', // nldone
        en: 'Topic',
    },

    atlas: {
        fr: 'Atlas',
        nl: 'Atlas', // nldone
        en: 'Atlas',
    },

    atlasEnv: {
        fr: 'Atlas de l’environnement',
        nl: 'Atlas van het milieu', // nldone
        en: 'Environnement atlas',
    },

    userName: {
        fr: 'Nom d’utilisateur',
        nl: 'Gebruikersnaam', // nldone
        en: 'User name',
    },

    password: {
        fr: 'Mot de passe',
        nl: 'Wachtwoord', // nldone
        en: 'Password',
    },

    alias: {
        fr: 'Alias',
        nl: 'Alias', // nldone
        en: 'Alias',
    },

    studio: {
        fr: 'Studio',
        nl: 'Studio', // nldone
        en: 'Studio',
    },

    metadata: {
        fr: 'Métadonnées',
        nl: 'Metadata', // nldone
        en: 'Metadatas',
    },

    'angled:universe': {
        fr: 'Termes',
        en: 'Terms',
        nl: 'Termen', // nldone
    },

    'angled:projects': {
        fr: 'Projets',
        en: 'Projects',
        nl: 'Projecten', // nldone
    },
    'angled:query': {
        fr: 'Requête',
        en: 'Query',
        nl: 'Zoekopdracht', // nldone
    },

    thesaurus: {
        fr: 'Thesaurus',
        nl: 'Thesaurus', // nldone
        en: 'Thesaurus',
    },

    columnID: {
        fr: 'Identifiant de colonne',
        nl: 'Kolomnaam', // nldone
        en: 'Column ID',
    },

    zoomIn: {
        fr: 'Zoomer',
        nl: 'Inzoomen', // nldone
        en: 'Zoom in',
    },

    zoomOut: {
        fr: 'Dézoomer',
        nl: 'Uitzoomen', // nldone
        en: 'Zoom out',
    },
    zoomRegio: {
        fr: '(Dé)zoom sur la région',
        nl: '(Uit)zoom op de regio',
        en: 'Zoom to region',
    },
    zoomToAddress: {
        fr: "Zoomer sur l'adresse",
        nl: 'Zoom in op het adres',
    },
    analyseAtAddress: {
        fr: "Analyse géothermique à l'adresse",
        nl: 'Geothermische analyse op het adres',
    },

    north: {
        fr: 'Nord',
        nl: 'Noorden', // nldone
        en: 'North',
    },

    fullscreen: {
        fr: 'Plein écran',
        nl: 'Volledige schermweergave', // nldone
        en: 'Full screen',
    },

    translate: {
        fr: 'Traduire',
        nl: 'Vertalen', // nldone
        en: 'Translate',
    },

    switch: {
        fr: 'Changer',
        nl: 'Omschakelen', // nldone
        en: 'Switch',
    },

    edit: {
        fr: 'Éditer',
        nl: 'Bewerken', // nldone
        en: 'Edit',
    },

    load: {
        fr: 'Charger',
        nl: 'Laden', // nldone
        en: 'Load',
    },

    'toggle-off': {
        fr: 'Désactiver',
        nl: 'Deactiveren', // nldone
        en: 'Toggle off',
    },

    'toggle-on': {
        fr: 'Activer',
        nl: 'Activeren', // nldone
        en: 'Toggle on',
    },

    'move-down': {
        fr: 'Redescendre',
        nl: 'Naar beneden gaan', // nldone
        en: 'Move down',
    },

    'move-up': {
        fr: 'Remonter',
        nl: 'Naar boven gaan', // nldone
        en: 'Move up',
    },

    view: {
        fr: 'Visiblité',
        nl: 'Zichtbaarheid', // nldone
        en: 'Visibility',
    },

    settings: {
        fr: 'Réglages',
        nl: 'Instellingen', // nldone
        en: 'Settings',
    },

    filter: {
        fr: 'Filtrer',
        nl: 'Filteren', // nldone
        en: 'Filter',
    },

    startDate: {
        fr: 'Date de début',
        nl: 'Startdatum', // nldone
        en: 'Start date',
    },

    endDate: {
        fr: 'Date de fin',
        nl: 'Einddatum', // nldone
        en: 'End date',
    },

    layerInfoSettings: {
        fr: 'Éditer la fiche individuelle',
        nl: 'Bewerk de individuele fiche', // nldone
        en: 'Edit feature view',
    },

    layerInfoSwitchMap: {
        fr: 'Carte',
        nl: 'Kaart', // nldone
        en: 'Map',
    },

    layerInfoSwitchTable: {
        fr: 'Table attributaire',
        nl: 'Tabel van attributen', // nldone
        en: 'Attribute table',
    },

    tableCountLabel: {
        fr: 'Total',
        nl: 'Totaal', // nldone
        en: 'Total',
    },

    print: {
        fr: 'Imprimer',
        nl: 'afdrukken', // nltocheck
        en: 'print',
    },

    printFormatChoice: {
        fr: 'Choix du format et de l’orientation',
        nl: 'Keuze van formaat en oriëntatie', // nldone
        en: 'Choice of format and orientation',
    },

    downloadCSV: {
        fr: 'Télécharger le fichier CSV',
        nl: 'Download CSV-bestand', // nldone
        en: 'Download as CSV file',
    },

    inspireCompliant: {
        fr: 'Interne & Catalogue',
        nl: 'Intern & Catalogus', // nldone
        en: 'Internal & Catalog',
    },

    internal: {
        fr: 'Interne',
        nl: 'Intern', // nldone
        en: 'Internal',
    },

    solarAppName: {
        fr: 'Carte solaire de la Région de Bruxelles-Capitale',
        nl: 'Zonnekaart van het Brussels Hoofdstedelijk Gewest', // nldone
        en: 'Brussels solar map',
    },

    unitEuro: {
        fr: '€',
        nl: '€', // nldone
        en: '€',
    },
    unitEuroExclVAT: {
        fr: '€HTVA',
        nl: '€ Excl.BTW', // nldone
        en: '€ Excl.VAT',
    },
    unitEuroInclVAT: {
        fr: '€TVAC',
        nl: '€ Incl.BTW', // nldone
        en: '€ Incl.VAT',
    },
    unitEuroY: {
        fr: '€/an',
        nl: '€/jaar', // nldone
        en: '€/year',
    },
    unitEuroY10: {
        fr: '€ / 10 ans',
        nl: '€ / 10 jaar', // nldone
        en: '€ / 10 years',
    },
    unitEuroY25: {
        fr: '€ / 25 ans',
        nl: '€ / 25 jaar', // nldone
        en: '€ / 25 years',
    },
    unitEuroKWh: {
        fr: '€/kWh',
        nl: '€/kWh', // nldone
        en: '€/kWh',
    },
    unitKWc: {
        fr: 'kWc',
        nl: 'kWp', // nldone
        en: 'kWc',
    },
    unitTonsCO2: {
        fr: '{value, plural, =0 {tonne CO2} =1 {tonne CO2} other {tonnes CO2}}',
        nl: '{value, plural, =0 {ton CO2} =1 {ton CO2} other {ton CO2}}',
        en: '{value, plural, =0 {ton CO2} =1 {ton CO2} other {tons CO2}}',
        parameters: {
            value: 1,
        },
    },
    unitTCO2: {
        fr: 'TCO2',
        nl: 'TCO2', // nldone
        en: 'TCO2',
    },
    unitTCO2Y: {
        fr: 'TCO2/an',
        nl: 'TCO2/jaar', // nldone
        en: 'TCO2/year',
    },
    unitTCO2Y10: {
        fr: 'TCO2 / 10 ans',
        nl: 'TCO2 / 10 jaar', // nldone
        en: 'TCO2 / 10 years',
    },
    unitTCO2Y25: {
        fr: 'TCO2 / 25 ans',
        nl: 'TCO2 / 25 jaar', // nldone
        en: 'TCO2 / 25 years',
    },
    unitYear: {
        fr: '{value, plural, =0 {an} =1 {an} other {ans}}',
        nl: '{value, plural, =0 {jaar} =1 {jaar} other {jaar}}', // nldone
        en: '{value, plural, =0 {years} =1 {year} other {years}}',
        parameters: {
            value: 1,
        },
    },
    unitM2: {
        fr: 'm²',
        nl: 'm²', // nldone
        en: 'm²',
    },
    unitM: {
        fr: 'm',
        nl: 'm',
        en: 'm',
    },
    unitPercent: {
        fr: '%',
        nl: '%', // nldone
        en: '%',
    },
    unitKWh: {
        fr: 'kWh',
        nl: 'kWh', // nldone
        en: 'kWh',
    },
    unitKWhY: {
        fr: 'kWh/an',
        nl: 'kWh/jaar', // nldone
        en: 'kWh/year',
    },
    unitLiterDay: {
        fr: 'l/jour',
        nl: 'l/dag', // nldone
        en: 'l/day',
    },
    unitLiter: {
        fr: 'l',
        nl: 'l', // nldone
        en: 'l',
    },

    name: {
        fr: 'Nom',
        nl: 'Naam', // nldone
        en: 'Name',
    },

    language: {
        fr: 'Langue',
        nl: 'Taal', // nldone
        en: 'Language',
    },

    type: {
        fr: 'Type',
        nl: 'Type', // nldone
        en: 'Type',
    },

    level: {
        fr: 'Niveau',
        nl: 'Niveau', // nldone
        en: 'Level',
    },

    locality: {
        fr: 'Localité',
        nl: 'Plaats', // nldone
        en: 'Level',
    },

    street: {
        fr: 'Rue',
        nl: 'Straat', // nldone
        en: 'Street',
    },

    total: {
        fr: 'Total',
        nl: 'Totaal', // nldone
        en: 'Total',
    },

    imageRightDisclaimer: {
        fr: "Important : veuillez vous assurer que Perspective dispose des droits sur l'image uploadée, ou que celle-ci est libre de droit.",
        nl: 'Belangrijk: zorg ervoor dat de rechten voor het gebruik van de geüploade afbeelding toegestaan zijn binnen deze context.', // nltodo
        en: 'Important: please make sure that the rights of the uploaded image allow its use in this context.',
    },

    tutorial: {
        fr: 'Tutorial',
        nl: 'Tutorial',
        en: 'Tutorial',
    },

    placeholderInsertText: {
        fr: 'Insérez votre text',
        nl: 'Voeg uw tekst in', // nltocheck
        en: 'Insert your text',
    },

    equal: {
        fr: 'égal',
        nl: 'gelijk', // nltocheck
        en: 'equal',
    },

    greaterThan: {
        fr: 'supérieur à',
        nl: 'groter ann', // nltocheck
        en: 'greater than',
    },

    greaterThanOrEqual: {
        fr: 'suppérieur ou égal',
        nl: 'groter of gelijk aan', // nltocheck
        en: 'grater than or equal',
    },

    lessThan: {
        fr: 'inférieur à',
        nl: 'kleiner aan', // nltocheck
        en: 'less than',
    },

    lessThanOrEqual: {
        fr: 'inférieur ou égal',
        nl: 'kleiner of gelijk aan', // nltocheck
        en: 'less than or equal',
    },

    reset: {
        fr: 'réinitialiser',
        nl: 'reset', // nltocheck
        en: 'reset',
    },

    hue: {
        fr: 'teinte',
        nl: 'tint', // nltocheck
        en: 'hue',
    },

    saturation: {
        fr: 'saturation',
        nl: 'verzadiging', // nltocheck
        en: 'saturation',
    },

    lightness: {
        fr: 'luminosité',
        nl: 'lichtheid', // nltocheck
        en: 'lightness',
    },

    transparency: {
        fr: 'transparence',
        nl: 'transparantie', // nltocheck
        en: 'transparency',
    },
    documentation: {
        fr: 'Documentation',
        nl: 'Documentatie', // nltocheck
        en: 'Documentation',
    },

    help: {
        fr: 'Aide',
        nl: 'Help', // nltocheck
        en: 'Help',
    },

    monday: {
        fr: 'lundi',
        nl: 'mandaag',
    },

    tuesday: {
        fr: 'mardi',
        nl: 'dinsdag',
    },

    wendesday: {
        fr: 'mercredi',
        nl: 'woensdag',
    },

    thursday: {
        fr: 'jeudi',
        nl: 'donderdag',
    },

    friday: {
        fr: 'vendredi',
        nl: 'vrijdag',
    },

    saturday: {
        fr: 'samedi',
        nl: 'zaterdag',
    },

    sunday: {
        fr: 'dimanche',
        nl: 'zondag',
    },

    january: {
        fr: 'janvier',
        nl: 'januari',
    },

    february: {
        fr: 'février',
        nl: 'februari',
    },

    march: {
        fr: 'mars',
        nl: 'maart',
    },

    april: {
        fr: 'avril',
        nl: 'april',
    },

    may: {
        fr: 'mai',
        nl: 'mei',
    },

    june: {
        fr: 'juin',
        nl: 'juni',
    },

    july: {
        fr: 'juillet',
        nl: 'juli',
    },

    august: {
        fr: 'aout',
        nl: 'augustus',
    },

    september: {
        fr: 'septembre',
        nl: 'september',
    },

    october: {
        fr: 'octobre',
        nl: 'oktober',
    },

    november: {
        fr: 'novembre',
        nl: 'november',
    },

    december: {
        fr: 'décembre',
        nl: 'december',
    },

    skipStep: {
        fr: 'Passer cette étape',
        nl: 'Sla deze stap over',
    },

    true: {
        fr: 'oui',
        en: 'yes',
        nl: 'ja',
    },

    false: {
        fr: 'non',
        en: 'no',
        nl: 'nee',
    },
    layerZoomInvisible: {
        fr: 'Couche cachée à ce niveau de zoom. Cliquez pour la visualiser.',
        nl: 'Verborgen laag op dit zoomniveau. Klik om het te bekijken.',
        en: 'Hidden layer at this zoom level. Click to view.',
    },
    selectTerm: {
        fr: 'Sélectionner un terme',
        nl: 'selecteer een term',
        en: 'Select a term',
    },
    select: {
        fr: 'Sélectionner',
        nl: 'selecteer',
        en: 'Select',
    },
    unselect: {
        fr: 'Désélectionner',
        nl: 'Deselecteer',
        en: 'Unselect',
    },
    csvExtention: {
        fr: '.csv',
        nl: '.csv',
        en: '.csv',
    },
    shpExtention: {
        fr: '.shp',
        nl: '.shp',
        en: '.shp',
    },
    xlsxExtention: {
        fr: '.xlsx',
        nl: '.xlsx',
        en: '.xlsx',
    },
    pngExtention: {
        fr: '.png',
        nl: '.png',
        en: '.png',
    },
    geojsonExtention: {
        fr: '.geojson',
        nl: '.geojson',
        en: '.geojson',
    },
    gpsTracker: {
        fr: 'Tracker GPS',
        nl: 'GPS tracker', // nldone
        en: 'GPS tracker',
    },
    menu: {
        fr: 'Menu',
        nl: 'Menu', // nldone
        en: 'Menu',
    },
    changeBackgroundMap: {
        fr: 'Changer de fond de carte',
        nl: 'Wijzig de achtergrond', // nldone
        en: 'Change background map',
    },
    public_published: {
        fr: 'Public',
        nl: 'Publiek', // nldone
        en: 'Published',
    },
    internally_published: {
        fr: 'Interne',
        nl: 'Intern', // nldone
        en: 'private',
    },
    private: {
        fr: 'Privé',
        nl: 'Privé', // nldone
        en: 'private',
    },
    title: {
        fr: 'Titre',
        nl: 'Titel', // nldone
        en: 'Title',
    },
    geometryType: {
        fr: 'Geométrie',
        nl: 'Geometrie', // nldone
        en: 'Geometry',
    },
    'md/domain': {
        fr: 'Domaine',
        nl: 'Domain', //nltodo
        en: 'Domain',
    },
    'md/path': {
        fr: 'Chemin',
        nl: 'Pad', //nltodo
        en: 'Path',
    },
    temporalReference: {
        fr: 'Dernière mise à jour de la métadonnée',
        nl: 'Laatste update van de metagegevens', // nldone
        en: 'Last update of the metadata',
    },
    'md/module': {
        fr: 'Module',
        nl: 'Module', //nltodo
        en: 'Module',
    },
    simple: {
        fr: 'Carte avec légende',
        nl: 'Kaart met legende',
    },
    'all-tools': {
        fr: 'Carte avec outils',
        nl: 'Kaart met tools',
    },
};
