/*
 *  Copyright (C) 2020 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import * as debug from 'debug';

import { DIV, SPAN } from 'sdi/components/elements';
import { table } from 'sdi/components/table2';

import {
    tableQuery,
    streamLoadingStatus,
    getLayerSource,
    getExportLink,
} from '../../queries/table';
import {
    tableDispatch,
    selectFeatureFromRow,
    clearCurrentStreamData,
} from '../../events/table';
import { getCurrentName, getCurrentLayerInfo } from '../../queries/app';
import {
    setLayout,
    deselectTableView,
    clearSelectedFeatures,
} from '../../events/app';
import { AppLayout } from '../../shape/types';
import { makeIcon, makeLabelAndIcon } from '../button';
import { fromNullable, none, some } from 'fp-ts/lib/Option';
import tr, { fromRecord } from 'sdi/locale';

import { exportLinks } from 'sdi/components/export';
import { endHarvest } from 'view/src/events/harvest';
import { isHarvesting } from 'view/src/queries/harvest';
import { nameToString } from 'sdi/components/button/names';

const logger = debug('sdi:table/feature-collection');

const closeButton = makeIcon('close', 3, 'times', {
    position: 'right',
    text: () => tr.core('close'),
});

const clearFilterButton = makeLabelAndIcon('close', 2, 'times', () =>
    tr.view('clearFilter')
);

const renderStreamingIndicator = () => {
    const s = streamLoadingStatus();
    const inner = (() => {
        switch (s) {
            case 'error':
                return some(SPAN('fa', nameToString('exclamation-triangle')));
            case 'loading':
                return some(
                    SPAN(
                        {
                            className: 'fa fa-spin',
                            title: tr.core('loadingData'),
                        },
                        nameToString('spinner')
                    )
                );
            case 'no-stream':
                return none;
            case 'loaded':
                return some(SPAN('fa', nameToString('check')));
        }
    })();
    return SPAN('stream-status', inner);
};

const renderRowCount = () =>
    DIV(
        'table-row-count',
        SPAN('', `${tr.core('tableCountLabel')} : `),
        getLayerSource().data(tableQuery().window).filteredCount
    );

const toolbar = () =>
    DIV(
        { className: 'table-toolbar', key: 'table-toolbar' },
        DIV('table-title', getCurrentName(), renderStreamingIndicator()),
        DIV(
            'table-tools',
            getCurrentLayerInfo().chain(({ info, metadata, name }) =>
                fromNullable(info.exportable).map(exportable =>
                    exportable
                        ? some(
                              exportLinks(
                                  getExportLink(info, metadata),
                                  fromRecord(name)
                              )
                          )
                        : none
                )
            ),
            renderRowCount()
        ),
        isHarvesting().map(() => clearFilterButton(endHarvest)),

        closeButton(() => {
            clearSelectedFeatures();
            deselectTableView();
            setLayout(AppLayout.MapFS);
        })
    );

const renderTable = table(
    tableDispatch,
    tableQuery,
    selectFeatureFromRow,
    clearCurrentStreamData
);

export default () => renderTable(getLayerSource(), toolbar());

logger('loaded');
