import tr, { fromRecord } from 'sdi/locale';

import { DIV, H2, A } from 'sdi/components/elements';
import { navigateMap } from '../events/route';
import { getLinks, getMapUrlFromView } from '../queries/map';
import { notEmpty } from 'sdi/util';

export const renderRelated = () => {
    const links = getLinks('forward')
        .fold([], ls =>
            ls
                .filter(l => l.status === 'published')
                .map(l =>
                    A(
                        {
                            className: 'link forward',
                            href: getMapUrlFromView(l.id),
                            onClick: e => {
                                e.preventDefault(); // don't follow the link when just clicking, but let use de right-click functionalities
                                navigateMap(l.id || '###');
                            },
                        },
                        fromRecord(l.title)
                    )
                )
        )
        .concat(
            getLinks('backward').fold([], ls =>
                ls
                    .filter(l => l.status === 'published')
                    .map(l =>
                        A(
                            {
                                className: 'link backward',
                                href: getMapUrlFromView(l.id),
                                onClick: e => {
                                    e.preventDefault(); // don't follow the link when just clicking, but let use de right-click functionalities
                                    navigateMap(l.id || '###');
                                },
                            },
                            fromRecord(l.title)
                        )
                    )
            )
        );

    return notEmpty(links).map(l =>
        DIV(
            { className: 'related-maps' },
            H2({}, tr.view('relatedMapsLabel')),
            ...l
        )
    );
};
