/*
 *  Copyright (C) 2017 Atelier Cartographique <contact@atelier-cartographique.be>
 *
 *  This program is free software: you can redistribute it and/or modify
 *  it under the terms of the GNU General Public License as published by
 *  the Free Software Foundation, version 3 of the License.
 *
 *  This program is distributed in the hope that it will be useful,
 *  but WITHOUT ANY WARRANTY; without even the implied warranty of
 *  MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 *  GNU General Public License for more details.
 *
 *  You should have received a copy of the GNU General Public License
 *  along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

import { ReactNode } from 'react';
import { none } from 'fp-ts/lib/Option';

import { DIV, H2, H3, LABEL } from 'sdi/components/elements';
import { isENTER } from 'sdi/components/keycodes';
import { attrOptions, inputNullableNumber } from 'sdi/components/input';
import { helpText } from 'sdi/components/helptext';

import tr from 'sdi/locale';
import { InteractionPosition } from 'sdi/map';

import queries from '../../queries/legend';
import {
    setPage,
    updatePositionerLatitude,
    updatePositionerLongitude,
} from '../../events/legend';
import {
    viewEvents,
    startPointerPosition,
    stopPointerPosition,
    putMark,
    startTracker,
} from '../../events/map';
import { getPointerPosition } from '../../queries/map';
import bookmark from '../bookmark';
import { makeLabelAndIcon, makeIcon } from '../button';

const getPositionerPos = () => queries.toolsPositioner().point;

const position = () => {
    const state = queries.toolsPositioner();
    viewEvents.updateMapView({
        center: [state.point.longitude, state.point.latitude],
        dirty: 'geo',
        zoom: 12,
    });
    putMark([state.point.longitude, state.point.latitude]);
};

const wrapLocation = (...children: ReactNode[]) =>
    DIV(
        { className: 'sidebar-main tool location' },
        H2({}, tr.view('location')),
        DIV(
            { className: 'tool-body lat-lon' },
            DIV({}, tr.view('helptext:locationTool')),
            ...children
        )
    );
const wrapBookmark = (...children: ReactNode[]) =>
    DIV(
        { className: 'sidebar-main tool location' },
        H2({}, tr.view('addBookmark')),
        DIV(
            { className: 'tool-body lat-lon' },
            DIV({}, tr.view('helptext:bookmark')),
            ...children
        )
    );

const btnStopPointerPosition = makeLabelAndIcon('close', 2, 'stop', () =>
    tr.view('stop')
);

const renderLatLon = ({ state }: InteractionPosition) =>
    DIV(
        { className: 'cursor-location' },
        H2({}, tr.view('cursorLocalisation')),
        helpText(tr.view('helptext:cursorLocationOn')),
        DIV(
            { className: 'lat-lon-label' },
            H3({}, tr.view('longitude'), ' : ', state.coordinates[0].toFixed()),
            H3({}, tr.view('latitude'), ' : ', state.coordinates[1].toFixed())
        ),
        btnStopPointerPosition(() => stopPointerPosition(none))
    );

const renderPointerPosition = (interactionPosition: InteractionPosition) =>
    wrapLocation(renderLatLon(interactionPosition));

const renderBookmark = (interactionPosition: InteractionPosition) =>
    wrapBookmark(renderLatLon(interactionPosition));

const getOrNull = (n: number) => (n === 0 ? null : n);

const latitudeInput = () =>
    LABEL(
        'label-latitude',
        tr.view('latitude'),
        inputNullableNumber(
            attrOptions(
                'legend-tool-positioner-lat',
                () => getOrNull(getPositionerPos().latitude),
                updatePositionerLatitude,
                {
                    name: 'lat',
                    // placeholder: tr.view('latitude'),
                    // onKeyPress: e => {
                    //     if (isENTER(e)) {
                    //         position();
                    //     }
                    // },
                }
            )
        )
    );

const longitudeInput = () =>
    LABEL(
        'label-longitude',
        tr.view('longitude'),
        inputNullableNumber(
            attrOptions(
                'legend-tool-positioner-lon',
                () => getOrNull(getPositionerPos().longitude),
                updatePositionerLongitude,
                {
                    name: 'lon',
                    // placeholder: tr.view('longitude'),
                    onKeyPress: e => {
                        if (isENTER(e)) {
                            position();
                        }
                    },
                }
            )
        )
    );

const btnPointerLocationStart = makeLabelAndIcon('start', 2, 'crosshairs', () =>
    tr.view('cursorLocalisation')
);
const btnLocationSearch = makeIcon('start', 2, 'search', {
    position: 'top-left',
    text: () => tr.core('search'),
});

const pointLocation = () =>
    DIV(
        { className: 'point-location' },
        H3({}, tr.view('pointLocation')),
        DIV(
            { className: 'lat-lon-inputs' },
            longitudeInput(),
            latitudeInput(),
            btnLocationSearch(position)
        ),
        helpText(tr.view('helptext:pointLocationTool'))
    );

const pointerLocation = () =>
    DIV(
        { className: 'pointer-location' },
        H3({}, tr.view('cursorLocalisation')),
        DIV(
            {},
            btnPointerLocationStart(() => {
                setPage('locate');
                startPointerPosition(putMark);
            })
        ),
        helpText(tr.view('helptext:cursorLocationOff'))
    );

const btnStartTracker = makeLabelAndIcon('start', 2, 'location-arrow', () =>
    tr.view('startGPS')
);

const gpsTracker = () =>
    DIV(
        {},
        H3({}, tr.core('gpsTracker')),
        btnStartTracker(startTracker),
        helpText(tr.view('helptext:gpsTracker'))
    );

const renderInput = () =>
    wrapLocation(pointLocation(), pointerLocation(), gpsTracker(), bookmark());

const render = (currentPage: 'locate' | 'locate-bookmark' = 'locate') => {
    switch (currentPage) {
        case 'locate':
            return getPointerPosition().foldL(
                renderInput,
                renderPointerPosition
            );
        case 'locate-bookmark':
            return getPointerPosition().foldL(renderInput, renderBookmark);
    }
};

export default render;
